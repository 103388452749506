<main class="content" id="name_entering" *ngIf="page == 'name_entering'">
    <div class="logo"></div>

    <form class="formTeamName">
        <input
                name="team_name"
                class="form-control formTeamName-input"
                autocomplete="off"
                placeholder="Название команды"
                type="text"
                [(ngModel)]="teamName.current"
        />
        <button
                [class]="(teamName.current && teamName.current === teamName.set) ? 'formTeamName-button_saved formTeamName-button btn' : 'formTeamName-button btn'"
                [disabled]="teamName.current.length === 0"
                (click)="setTeamName()"
        >Сохранить
        </button>
    </form>
</main>

<main class="content" id="not_started" *ngIf="page == 'not_started'">
    <div class="header">
        <div class="logo"></div>
        <div class="teamName">{{teamName.set}}</div>
    </div>

    <p class="textWaiting">Ожидаем остальные команды...</p>
</main>

<main class="content" id="answers_entering" *ngIf="page == 'answers_entering'">
    <div class="header">
        <div class="logo"></div>
        <div class="round">
            <div class="round-num">Раунд {{roundNum}}</div>
            <div class="round-name">{{roundName}}</div>
        </div>
        <div class="teamName">{{teamName.set}}</div>
    </div>

    <form class="inputsList" novalidate method="post">
        <div class="inputsListWrapper">
            <div class="inputListItem" *ngFor="let answer of answers; let i = index">
                <div class="inputListItem-Form">
                    <label class="inputListItem-Label" for="answer_{{i}}">Вопрос {{i + 1}}.</label>
                    <input
                            id="answer_{{i}}"
                            name="answer_{{i}}"
                            class="form-control inputListItem-Input"
                            autocomplete="off"
                            placeholder="Введите ответ"
                            type="text"
                            [(ngModel)]="answer.current"
                            [disabled]="answer.disabled"
                    />

                    <button
                            class="inputListItem-Button btn"
                            (click)="setAnswer(i)"
                            [disabled]="answer.disabled || answer.set === answer.current"
                    >Сохранить
                    </button>
                </div>
                <div class="inputListItem-Answer" *ngIf="answer.set">Принятый ответ: {{answer.set}}</div>
                <div class="inputListItem-Answer" *ngIf="!answer.set"></div>
            </div>
        </div>
    </form>
</main>