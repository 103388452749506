import { Component, OnInit, OnDestroy } from '@angular/core';
import { CheckStreamingService } from '../../../../services/check-streaming.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'bg-circle',
  templateUrl: './bg-circle.component.html',
  styleUrls: ['./bg-circle.component.css'],
  template: '<bg-circle *ngIf="isVisible"><bg-circle/>',
})
export class BgCircleComponent implements OnInit, OnDestroy {
  isVisible: boolean = false;
  private subscription?: Subscription;


  constructor(private checkStreamingService: CheckStreamingService) { }

  ngOnInit(): void {
    // подписываемся на обновления из сервиса CheckStreamingService
    this.subscription = this.checkStreamingService.showStreaming.subscribe((show) => {
      // console.log({show})
      this.isVisible = show; // получаем значение и обновляем свойство компонента
    });

  }

  ngOnDestroy(): void {
     // не забываем отписаться, чтобы не было утечек памяти
     if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

}
