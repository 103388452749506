import { Component, OnInit } from '@angular/core';

import { PokerService } from 'src/app/services/poker.service';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.css']
})
export class PlayComponent implements OnInit {
  users: any = [];
  log: any = "";
  openedCards: any = [];
  actions: any = [];
  value: any = 10;
  constructor(private pokerService: PokerService) {}
  
  ngOnInit(): void {
    this.getGame();
  }

  getGame() {
    this.pokerService.getGame().subscribe({
      next: (data) => {
        this.users = data["users"];
        this.log = data["log"];
        this.actions = data["actions"];
        this.openedCards = data["opened_cards"];
      },
      error: (e) => console.error(e)
    });
  }

  doAction(action) {
    this.pokerService.doAction(action, this.value).subscribe({
      next: (data) => {
        this.users = data["users"];
        this.log = data["log"];
        this.actions = data["actions"];
        this.openedCards = data["opened_cards"];
      },
      error: (e) => console.error(e)
    });
  }

}
