import { Component, OnInit } from '@angular/core';
import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-admin-logic-games',
  templateUrl: './admin-logic-games.component.html',
  styleUrls: ['./admin-logic-games.component.css']
})
export class AdminLogicGamesComponent implements OnInit {
  games: any = [];

  constructor(private commonService: CommonService, private logicService: LogicService) { }

  ngOnInit(): void {
    this.getGames();
  }

  getGames() {
    this.logicService.getGames().subscribe({
      next: (data) => {
        this.games = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  clearGame(gameId) {
    this.logicService.clearGame(gameId).subscribe({
      next: (data) => {
        window.location.href = `admin/logic/game/${gameId}/process`;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

}
