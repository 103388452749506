import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-scaled-text",
  templateUrl: "./scaled-text.component.html",
  styleUrls: ["./scaled-text.component.css"],
})
export class ScaledTextComponent implements OnInit, OnChanges {
  @Input() breakpoints: Record<number, number> = {
    25: 100,
    50: 90,
    100: 72,
    150: 58,
    200: 48,
    500: 45,
    1000: 42,
    1500: 38,
    2000: 32,
  };

  @Input() hasPicture: boolean = false;
  @Input() hasExtraBlock: boolean = false;

  @Input() text: string = "";

  _fontSize: string = "24px";

  constructor() {}

  setFontSize(): void {
    const breakpoint = Object.keys(this.breakpoints)
      .sort((a, b) => +a - +b)
      .find((el) => +el > this.text.length);

    let current_size = (breakpoint && this.breakpoints[breakpoint]) || 24;
    if (this.hasPicture) {
      current_size *= 0.7;
    }
    if (this.hasExtraBlock) {
      current_size *= 0.7;
    }
    this._fontSize = Math.floor(current_size) + "px";
  }

  ngOnInit(): void {
    this.setFontSize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFontSize();
  }
}
