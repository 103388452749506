import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  processError(e: any): any {
    if (e.status == 422 || e.status == 401) {
      window.location.href = `/login?from=${window.location.href}`;
      return
    }
    console.error(e);
  }
}
