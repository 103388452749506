<div class="main-question">
    <div *ngIf="problem.images?.[0] && !problem.answer" class="main-question-image">
        <img src="{{ problem.images?.[0] }}" alt="" />
    </div>
    <div  class="main-question-text" *ngIf="problem.description && !problem.answer">
    	<app-scaled-text [hasPicture]="problem.images?.[0]" [text]="problem.description"></app-scaled-text>
    </div>

    <app-answer *ngIf="problem.answer" [problem]="problem"></app-answer>
</div>
