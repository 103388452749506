import { Component, OnInit, OnDestroy } from '@angular/core';
import { CheckStreamingService } from '../../../../services/check-streaming.service';
import { Subscription } from 'rxjs';

/** Если хочется нарисовать что-то специфичное для редизайна
 * в каком-то общем месте, надо завернуть в этот компонент
 */
@Component({
  selector: 'yoko-redesign-only',
  template: '<ng-content *ngIf="isVisible"></ng-content>',
})
export class YokoRedesignOnlyComponent implements OnInit, OnDestroy {
  isVisible: boolean = false;
  private subscription?: Subscription;


  constructor(private checkStreamingService: CheckStreamingService) { }

  ngOnInit(): void {
    // подписываемся на обновления из сервиса CheckStreamingService
    this.subscription = this.checkStreamingService.showStreaming.subscribe((show) => {
      this.isVisible = show; // получаем значение и обновляем свойство компонента
    });

  }

  ngOnDestroy(): void {
     // не забываем отписаться, чтобы не было утечек памяти
     if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

}
