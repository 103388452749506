import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { ENV } from '.env'

const baseUrl = `${ENV.apiUrl}/api`;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  get_headers() {
    let headers = new HttpHeaders();
    let token = this.cookieService.get("auth_token");

    return headers.set("Authorization", `Bearer ${token}`);
  }

  login(login_form: any): any {
    return this.http.post<any>(`${baseUrl}/login/`, login_form);
  }

  get_users(): any {
    return this.http.get<any>(`${baseUrl}/users`, {headers: this.get_headers()});
  }

  get_user(id): any {
    return this.http.get<any>(`${baseUrl}/user/${id}`, {headers: this.get_headers()});
  }

  save_user(user): any {
    return this.http.post<any>(`${baseUrl}/users`, user, {headers: this.get_headers()});
  }

}
