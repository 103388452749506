import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from 'src/app/services/common.service';
import { GameStatisticsService } from 'src/app/services/game-statistics.service';

@Component({
  selector: 'app-admin-game-statistics-players',
  templateUrl: './admin-game-statistics-players.component.html',
  styleUrls: ['./admin-game-statistics-players.component.css']
})
export class AdminGameStatisticsPlayersComponent implements OnInit {
  players: any = undefined;
  game_id: any = undefined;

  constructor(private common_service: CommonService, private game_statistics_service: GameStatisticsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.game_id = this.route.snapshot.params["game_id"];
    this.get_players();
  }

  get_players(): void {
    this.game_statistics_service.get_players(this.game_id)
      .subscribe({
        next: (data) => {
          console.log(data);
          this.players = data;
        },
        error: (e) => this.common_service.processError(e)
      });
  }

}
