import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logic-hundred-to-one-problem',
  templateUrl: './logic-hundred-to-one-problem.component.html',
  styleUrls: ['./logic-hundred-to-one-problem.component.css']
})
export class LogicHundredToOneProblemComponent implements OnInit {

  @Input() problem: any = undefined;
  fontSize: any = 80;

  constructor() { }

  ngOnInit(): void {
  }

}
