<div *ngFor="let user of users">
	Имя: {{user.name}}
	<br/>
	Счет: {{user.money}}
	<br/>
	Общая ставка: {{user.all_bet}}
	<br/>
	Текущая ставка: {{user.current_bet}}
	<br/>
	<div *ngIf="user.name == 'AlexBurkov'">
		<img
				*ngFor="let card of user.cards"
				alt="Изображение не загружено"
				src="/assets/poker/{{card[0]}}_{{card[1]}}.png"
				width="90px"/>
		<br/>

		<div *ngFor="let action of actions">
			<input *ngIf="action == 'raise'" type="text" [(ngModel)]="value" />
			<input type="button" value="{{action}}" (click)="doAction(action)"/>
			<br/>
		</div>
	</div>
	<br/>
</div>

<div>
	Открытые карты <br/>
	<img
			*ngFor="let card of openedCards"
			alt="Изображение не загружено"
			src="/assets/poker/{{card[0]}}_{{card[1]}}.png"
			width="90px"
	/>
</div>
<br/>
<br/>
<br/>

<div>
	<p *ngFor="let line of log">
		{{line}}
	</p>
</div>

<!-- <div *ngFor="let i of [].constructor(4); let suit = index">
	<img *ngFor="let j of [].constructor(13); let value = index" src="/assets/poker/{{value + 2}}_{{suit}}.png" width="90px"/>
	<br/>
</div>
 -->
