<div class="main-question">
    <div *ngIf="problem.images?.[0]" class="main-question-image">
        <img src="{{ problem.images?.[0] }}" alt="" />
    </div>

    <div *ngIf="problem.video_url" class="main-question-image">
        <video src="{{problem.video_url}}" id="video-player" width="600" height="400"> </video>
    </div>

    <div class="main-question-text" *ngIf="problem.description">
        <app-scaled-text [hasExtraBlock]="true" [hasPicture]="problem.images?.[0]" [text]="problem.description"></app-scaled-text>
    </div>

    <!-- <app-answer *ngIf="problem.answer" [problem]="problem"></app-answer> -->
</div>
<div class="variants-container">
    <app-variant *ngFor="let item of problem.options; let i = index" [variant]="{seqNumber: _INDEX_CHARS_MAP[i], text: item, isCorrect: problem.answer?.description == i}"></app-variant>
</div>
