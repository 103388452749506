import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-logic',
  templateUrl: './logic.component.html',
  styleUrls: ['./logic.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LogicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
