import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-admin-logic-round-edit',
  templateUrl: './admin-logic-round-edit.component.html',
  styleUrls: ['./admin-logic-round-edit.component.css']
})
export class AdminLogicRoundEditComponent implements OnInit {
  logicRound: any = undefined;
  roundTypes: any;
  isCreating: any;

  constructor(private commonService: CommonService, private logicService: LogicService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isCreating = this.route.snapshot['_routerState'].url == "/admin/logic/rounds/new";
    
    if (this.isCreating) {
      this.logicRound = {}
    } else {
      this.getRound(this.route.snapshot.params["id"]);
    }

    this.getRoundTypes();
  }

  getRound(id): void {
    this.logicService.getRound(id).subscribe({
      next: (data) => {
        this.logicRound = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  getRoundTypes() {
    this.logicService.getRoundTypes().subscribe({
      next: (data) => {
        this.roundTypes = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  submit() {
    this.logicService.saveRound(this.logicRound).subscribe({
      next: (data) => window.location.href='/admin/logic/rounds',
      error: (e) => console.error(e)
    });
  }
}
