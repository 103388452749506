<div class="main-content">
    <div *ngIf="!problem.answer" class="music-problem">
        <div class="music-problem-image">
            <img *ngIf="problem.images?.[0]" src="{{ problem.images?.[0] }}" alt="" />
            <div *ngIf="!problem.images?.[0]" class="music-problem-image__default">
                <div class="music-problem-image-sign"></div>
            </div>
        </div>
        <div>{{problem.description}}</div>
    </div>
    <app-answer *ngIf="problem.answer" [problem]="problem"></app-answer>
</div>
