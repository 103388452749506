import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { ENV } from '.env'

const baseUrl = `${ENV.apiUrl}/api`;

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  uploadImage(image: any): any {
    const formData = new FormData();
    formData.append("image", image, image.name);

    let token = this.cookieService.get("auth_token");
    let headers = new HttpHeaders();

    headers = headers.set("Authorization", `Bearer ${token}`);
    return this.http.post<any>(`${baseUrl}/images/upload`, formData, {headers: headers});
  }

  uploadImageByUrl(url) {
    let token = this.cookieService.get("auth_token");
    let headers = new HttpHeaders();

    headers = headers.set("Authorization", `Bearer ${token}`);
    return this.http.post<any>(`${baseUrl}/images/upload`, {"url": url}, {headers: headers});
  }

  uploadVideoByUrl(url) {
    let token = this.cookieService.get("auth_token");
    let headers = new HttpHeaders();

    headers = headers.set("Authorization", `Bearer ${token}`);
    return this.http.post<any>(`${baseUrl}/videos/upload`, {"url": url}, {headers: headers});
  }

}
