<div class="row g-3" *ngIf="game !== undefined">
    <div class="col-md-7 col-lg-8">
        <form class="" novalidate="" method="post">
            <h4 class="mb-3">Игра</h4>
            <div class="row g-3">
                <div class="col-sm-6">
                    <label class="form-label" for="name">Название</label>
                    <input id="name" name="name" class="form-control" value="{{game.name}}" [(ngModel)]="game.name" />
                </div>
            </div>
            <hr class="my-4">
            <div>
                <h4 class="mb-3">Задание</h4>

                <div class="row g-3">
                    <div class="col-sm-12">
                        <label for="description" class="form-label">Информация</label>
                        <textarea class="form-control" id="description" name="info" [(ngModel)]="game.info" rows="3"></textarea>
                    </div>
                </div>
            </div>
            <div class="row g-3">
                <input type="button" class="btn btn-primary" value="Сохранить" (click)="submit()" />
            </div>
        </form>
    </div>
</div>
