import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-logic-index',
  templateUrl: './admin-logic-index.component.html',
  styleUrls: ['./admin-logic-index.component.css']
})
export class AdminLogicIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
