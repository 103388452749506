<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Задания</h1>
</div>
<table class="table">
    <thead>
        <tr>
            <th scope="col">Название</th>
            <th scope="col">Задание</th>
            <th scope="col">Ответ</th>
            <th scope="col">Добавить в игру</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let problem of problems; let i = index">
            <td>{{problem.name}}</td>
            <td>
                <img
                        *ngFor="let image_url of problem.question_images"
                        src="{{image_url}}"
                        style="max-width: 150px;max-height: 150px; margin: 3px;"
                        alt="Изображение не загружено"
                />
            </td>
            <td>
                <img
                        alt="Изображение не загружено"
                        src="{{problem.answer_image}}"
                        style="max-width: 150px;max-height: 150px; margin: 3px;"
                />
            </td>
            <td>
                <input
                	class="added_problem"
                	type="checkbox"
                	[(ngModel)]="problems[i].checked"
                	(change)="toggleProblem(i)"
                />
            </td>
        </tr>
    </tbody>
</table>
