import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-logic-self-game-problem',
  templateUrl: './logic-self-game-problem.component.html',
  styleUrls: ['./logic-self-game-problem.component.css']
})
export class LogicSelfGameProblemComponent implements OnInit {

  @Input() problem: any = undefined;

  fontSize: any = 80;

  constructor() { }

  ngOnInit(): void {
  }

}
