import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-logic-millionaire-problem",
  templateUrl: "./logic-millionaire-problem.component.html",
  styleUrls: ["./logic-millionaire-problem.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class LogicMillionaireProblemComponent implements OnInit {
  fontSize: number = 38;

  @Input() problem: any = undefined;

  _INDEX_CHARS_MAP = ["А", "Б", "В", "Г", "Д"];

  constructor() {}

  ngOnInit(): void {
    console.log(this.problem?.answer?.text == this._INDEX_CHARS_MAP[1]);
  }
}
