<div class="card-wrap">
  <div
    class="anim-object {{!isPlaceholder ? 'active' : ''}} rotate-hor-center"
    id="card-object-hf"
  >
    <div class="front face">
      <div class="card-placeholder">
        <div class="card-placeholder-marker">
          {{ variant?.seqNumber }}
        </div>
      </div>
    </div>
    <div class="back face">
      <app-variant [variant]="variant"></app-variant>
    </div>
  </div>
</div>
