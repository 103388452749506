<div class="row g-3" *ngIf="logicRound !== undefined">
    <div class="col-md-7 col-lg-8">
        <h4 class="mb-3">Данные о раунде</h4>

        <form action="" method="post">
            <div class="row g-3">
                <div class="col-sm-6">
                    <label class="form-label" for="name">Название</label>
                    <input id="name" name="name" class="form-control" [(ngModel)]="logicRound.name" />
                </div>
                <div class="col-sm-6">
                    <label class="form-label" for="description">Описание</label>
                    <input id="description" name="description" class="form-control" [(ngModel)]="logicRound.description" />
                </div>
            </div>
            <hr class="my-4">
            <div class="round row g-3">
                <label class="form-label" for="roundType">Тип раунда</label>
                <select class="form-select" id="roundType" name="roundType" [(ngModel)]="logicRound.game_type">
                    <option *ngFor="let roundType of roundTypes; let i = index" value="{{roundType.code}}">{{roundType.name}}</option>
                </select>
            
            </div>
            <hr class="my-4">
            <p> <input type="button" id="submit" value="Сохранить" (click)="submit()" /> </p>
        </form>
    </div>
</div>
