export class ProblemStep  {
  description: string;
  options: Array<string>;
  letters?: string;
  audio_url?: string;
  audio_times?: string;
  video_url?: string;
  video_times?: string;
  images: Array<string>;
  is_answer_step: boolean;

  constructor(data?: any) {
    this.description = "";
    this.options = [];
    this.letters = undefined;
    this.audio_url = undefined;
    this.audio_times = undefined;
    this.video_url = undefined;
    this.video_times = undefined;
    this.images = [];

    this.is_answer_step = false;

    if (data) {
      if (data.description != undefined) this.description = data.description;
      if (data.options != undefined) this.options = data.options;
      if (data.letters != undefined) this.letters = data.letters;
      if (data.audio_url != undefined) this.audio_url = data.audio_url;
      if (data.audio_times != undefined) this.audio_times = data.audio_times;
      if (data.video_url != undefined) this.video_url = data.video_url;
      if (data.video_times != undefined) this.video_times = data.video_times;
      if (data.images != undefined) this.images = data.images;
    }

    // console.log(this.images);
  }
};

export class Problem  {
  id?: string;
  name?: string;
  round_id?: number;
  steps: Array<ProblemStep>;

  constructor(data?: any) {
    this.id = undefined;
    this.name = undefined;
    this.round_id = undefined;
    
    let aps = new ProblemStep();
    aps.is_answer_step = true;
    this.steps = [new ProblemStep(), aps];

    if (data) {
      if (data.id != undefined) this.id = data.id;
      if (data.name != undefined) this.name = data.name;
      if (data.name != undefined) this.round_id = data.round_id;

      this.steps = [];
      for (let i = 0; i < data.steps?.length; i++) {
        this.steps.push(new ProblemStep(data.steps[i]));
      }

      aps = new ProblemStep(data.answer_step);
      aps.is_answer_step = true;
      this.steps.push(aps);
    }
  }
};