import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logic-logic-problem',
  templateUrl: './logic-logic-problem.component.html',
  styleUrls: ['./logic-logic-problem.component.css']
})
export class LogicLogicProblemComponent implements OnInit {

  @Input() problem: any = undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
