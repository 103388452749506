<div class="main-content">
    <table class="table" *ngIf="results.teams_results">
        <tr>
            <th>Команда</th>
            <th *ngFor="let game_round of results.game_rounds; let round_id = index" [ngClass]="{'active': round_id + 1 === results.active_round}">Раунд {{round_id + 1}}</th>
            <th [ngClass]="{'active': results.active_round == null}">ИТОГО</th>
        </tr>
        <tr *ngFor="let team of results.teams">
            <td>{{team.name}}</td>
            <td *ngFor="let game_round of results.game_rounds; let round_id = index" [ngClass]="{'active': round_id + 1 === results.active_round}">{{results.teams_results[team.name][game_round.id]}}</td>
            <td>{{results.teams_results[team.name][0]}}</td>
        </tr>
    </table>
</div>
