import { OnInit, Input, Component } from "@angular/core";

@Component({
  selector: 'app-game-results',
  templateUrl: './game-results.component.html',
  styleUrls: ['./game-results.component.css']
})
export class GameResultsComponent implements OnInit {
  @Input()
  results: any = null;

  ngOnInit(): void {
  }
}
