<div class="answer">
	<h1 class="answer-text">{{ problem.answer.description }}</h1>
  	<img
		class="answer-img"
		*ngIf="problem.answer.images[0]"
		width="500"
		src="{{ problem.answer.images[0] }}"
  	/>
  	<video
		*ngIf="problem.answer.video_url"
  		src="{{problem.answer.video_url}}"
  		id="video-player"
  		width="800"
  		height="600"
  	></video>
  	<video
		*ngIf="problem.answer.audio_url"
  		src="{{problem.answer.audio_url}}"
  		id="video-player"
  		width="10"
  		height="10"
  	></video>
</div>
