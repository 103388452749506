import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { ENV } from '.env'

const baseUrl = `${ENV.apiUrl}/api/admin/santa`;

@Injectable({
  providedIn: 'root'
})
export class SantaService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getHeaders() {
    let headers = new HttpHeaders();
    let token = this.cookieService.get("auth_token");

    return headers.set("Authorization", `Bearer ${token}`);
  }

  getGames() {
    return this.http.get(`${baseUrl}/games`, {headers: this.getHeaders()});
  }

  getGame(id) {
    return this.http.get(`${baseUrl}/game/${id}`, {headers: this.getHeaders()});
  }

  saveGame(game) {
    let url = `${baseUrl}/games/new`;
    if (game.id !== undefined) {
      url = `${baseUrl}/game/${game.id}/edit`;
    }
    return this.http.post<any>(url, game, {headers: this.getHeaders()});
  }

  startGame(game_id) {
    return this.http.post<any>(`${baseUrl}/game/${game_id}/start`, {}, {headers: this.getHeaders()});
  }

}
