import { Component, OnInit } from '@angular/core';
import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-admin-logic-rounds',
  templateUrl: './admin-logic-rounds.component.html',
  styleUrls: ['./admin-logic-rounds.component.css']
})
export class AdminLogicRoundsComponent implements OnInit {
  rounds: any;

  constructor(private commonService: CommonService, private logicService: LogicService) { }

  ngOnInit(): void {
    this.getRounds();
  }

  getRounds() {
    this.logicService.getRounds().subscribe({
      next: (data) => {
        this.rounds = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  removeRound(id) {
    this.rounds.splice(id, 1);
  }

}
