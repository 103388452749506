<div class="row g-3" *ngIf="user !== undefined">
    <div class="col-md-7 col-lg-8">
        <form class="" novalidate="" method="post">
            <h4 class="mb-3">Пользователь</h4>
            <div class="row g-3">
                <div class="col-sm-6">
                    <label class="form-label" for="username">Username</label>
                    <input id="username" name="username" class="form-control" [(ngModel)]="user.username" />
                </div>
                <div class="col-sm-6">
                    <label class="form-label" for="telegram_id">Telegram ID</label>
                    <input id="telegram_id" name="telegram_id" class="form-control" [(ngModel)]="user.telegram_id" />
                </div>
            </div>

            <div class="row g-3">
                <div class="col-sm-6">
                    <label class="form-label" for="first_name">Имя</label>
                    <input id="first_name" name="first_name" class="form-control" [(ngModel)]="user.first_name" />
                </div>
                <div class="col-sm-6">
                    <label class="form-label" for="last_name">Фамилия</label>
                    <input id="last_name" name="last_name" class="form-control" [(ngModel)]="user.last_name" />
                </div>
            </div>
            <hr class="my-4">
            <div class="row g-3">
                <input type="button" class="btn btn-primary" value="Сохранить" (click)="submit()" />
            </div>
        </form>
    </div>
</div>
