import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LogicService } from 'src/app/services/logic.service';
import { timer } from 'rxjs';

import { Renderer2 } from '@angular/core';

import * as io from 'socket.io-client';

import { ENV } from '.env'

@Component({
  selector: 'app-logic-game-streaming',
  templateUrl: './logic-game-streaming.component.html',
  styleUrls: ['./logic-game-streaming.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LogicGameStreamingComponent implements OnInit {
  gameId: number;
  teamId: number;

  images: Array<string> = [];
  socket: any;
  seconds: number = 0;

  canBeShowed: boolean = false;

  state: any;
  roundName: any;
  roundType: any;
  notice: any = "";

  questionNum: number = 1;

  problem: any = undefined;
  page: any = undefined;

  // not started
  not_started = {"game_name": undefined}

  // teams showing
  teams_showing = {"current": undefined};

  // round_starting
  round_starting = {"round_name": undefined, "round_num": undefined};

  // results
  teams_info: any = undefined;

  // problem
  problem_page: any = {"with_options": false, "with_letters": false};
  header: any = {"round_name": undefined, "round_num": undefined, "question_num": undefined, "question_count": undefined, "ts": undefined};


  // results page
  results = {"game_rounds": undefined, "active_round": undefined, "teams": undefined, "teams_results": undefined};


  constructor(private renderer: Renderer2, private logicService: LogicService, private route: ActivatedRoute) {
    this.gameId = this.route.snapshot.params["game_id"];
    this.teamId = this.route.snapshot.params["team_id"];

    this.socket = io.connect(ENV.apiUrl);
    this.socket.on(`question_update_${this.gameId}`, (data) => {
      this.set_page_problem(data);
    });

    this.socket.on(`change_seconds_${this.gameId}`, (data) => {
      this.seconds = data["seconds"];
    });

    this.socket.on(`play_media_${this.gameId}`, (data) => {
      let player = this.renderer.selectRootElement(`#video-player`);
      player.currentTime = 0;
      player.play();
    });

    this.socket.on(`pause_media_${this.gameId}`, (data) => {
      let player = this.renderer.selectRootElement(`#video-player`);
      player.pause();
    });

    this.socket.on(`update_stream_page_${this.gameId}`, (data) => {
      this.getStreamPage();
    });

    timer(1000, 1000).subscribe(seconds => {
      if (this.seconds > 0) {
        this.seconds -= 1;
      }
    });
  }

  set_page_problem(data) {
    this.page = "problem";

    this.images = data.problem.images;

    this.roundName = data["round_name"];
    this.roundType = data["round_type"];

    this.problem = data.problem;
    this.header = data.header;

    if (this.state == "new_round") {
      this.notice = `Раунд ${this.roundName}`;
    }
  }

  set_page_not_started(data) {
    this.not_started = data;
  }

  set_page_teams_showing(data) {
    this.teams_showing.current = data["team_name"];
  }

  set_page_round_starting(data) {
    this.round_starting.round_name = data.round_name;
    this.round_starting.round_num = data.round_num;
  }

  set_page_results(data) {
    this.results.game_rounds = data["game_rounds"];
    this.results.active_round = data["active_round"];
    this.results.teams = data["teams"];
    this.results.teams_results = data["teams_results"];
  }

  ngOnInit(): void {
    this.getStreamPage();
  }

  updateStreamPage(data) {
    console.log(data);
    this.page = data["page"];
    data = data["data"];

    if (this.page == "not_started") {
      this.set_page_not_started(data);
    }
    if (this.page == "teams_showing") {
      this.set_page_teams_showing(data);
    }
    if (this.page == "problem") {
      this.set_page_problem(data);
    }
    if (this.page == "round_starting") {
      this.set_page_round_starting(data);
    }
    if (this.page == "results") {
      this.set_page_results(data);
    }
  }

  getStreamPage() {
    this.logicService.getStreamData(this.gameId).subscribe({
      next: (data) => {
        this.updateStreamPage(data);
      },
      error: (e) => console.error(e)
    });
  }


}
