import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Problem } from '../models/logic/problem.model';

import { CookieService } from 'ngx-cookie-service';

import { ENV } from '.env'

const baseUrl = `${ENV.apiUrl}/api/admin/game_statistics`;

@Injectable({
  providedIn: 'root'
})
export class GameStatisticsService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getHeaders() {
    let headers = new HttpHeaders();
    let token = this.cookieService.get("auth_token");

    return headers.set("Authorization", `Bearer ${token}`);
  }

  getGames() {
    return this.http.get(`${baseUrl}/games`, {headers: this.getHeaders()});
  }

  save_player(game_id, player) {
    return this.http.post<any>(`${baseUrl}/${game_id}/players/new`, player, {headers: this.getHeaders()});
  }

  get_players(game_id) {
    return this.http.get(`${baseUrl}/${game_id}/players`, {headers: this.getHeaders()});
  }

}