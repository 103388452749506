<h1>Игра {{game?.name}}</h1>

<div *ngFor="let round of game?.rounds; let round_idx = index" class="round">
    <h2>{{round.logic_round_name}}</h2>
    <input
        type="button"
        value="Расставить приоритеты"
        class="normalize"
        style="margin: 2px;"
        (click)="normalizeRound(game.id, round.id)"
    />
    <input type="button" value="Свернуть" class="button_collapse" style="margin: 2px;" (click)="toggleRound(round_idx)" />
    <div class="problems" *ngIf="round_showed[round_idx] !== undefined">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Ответ</th>
                    <th scope="col">Задание</th>
                    <th scope="col">Баллы</th>
                    <th scope="col">Порядок</th>
                    <th scope="col">Перейти</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let game_problem of round.problems; let problem_idx = index">
                    <td>{{game_problem.problem.name}}</td>
                    <td>
                        <div>
                            {{game_problem.problem.description}}
                            <img
                                    *ngFor="let image_url of game_problem.problem.images"
                                    alt="Изображение не загружено"
                                    src="{{image_url}}"
                                    style="max-width: 150px;max-height: 150px; margin: 3px;"
                            />
                        </div>
                    </td>
                    <td>
                        <input
                            value="{{game_problem.points}}"
                            class="points"
                            name="game_problem_points_{{problem_idx}}"
                            [(ngModel)]="game.rounds[round_idx].problems[problem_idx].points"
                            (change)="changePoints(game.id, round.id, $event, game_problem.id)"
                        />
                    </td>
                    <td>
                        <input
                            value="{{game_problem.priority}}"
                            class="priority"
                            name="game_problem_priority_{{problem_idx}}"
                            [(ngModel)]="game.rounds[round_idx].problems[problem_idx].priority"
                            (change)="changePriority(game.id, round.id, $event, game_problem.id)"
                        />
                    </td>
                    <td>
                        <a href="/admin/logic/problem/{{game_problem.problem.id}}/edit">Перейти</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <a href="/admin/logic/game/{{game.id}}/round/{{round.id}}">Выбрать задания</a>
</div>
