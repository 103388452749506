import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';

import * as io from 'socket.io-client';
import { ENV } from '.env'


@Component({
  selector: 'app-admin-logic-game-answers',
  templateUrl: './admin-logic-game-answers.component.html',
  styleUrls: ['./admin-logic-game-answers.component.css']
})
export class AdminLogicGameAnswersComponent implements OnInit {
  game_id: any = undefined;
  teams_info: any = undefined;
  questions: any = undefined;

  round_blocks: any = {};

  game_rounds: any = [];
  teams: any = [];
  team_answers: any = {};
  teams_results: any = {};

  socket: any = undefined;
  
  constructor(private logicService: LogicService, private commonService: CommonService, private route: ActivatedRoute) {
    this.game_id = this.route.snapshot.params["game_id"];

    this.socket = io.connect(ENV.apiUrl);

    this.socket.on(`team_answers_update_${this.game_id}`, (data) => {
      this.team_answers[data.game_problem_id][data.game_team_id] = data;
    });

  }

  ngOnInit(): void {
    this.get_answers();
  }

  get_answers(): void {
    this.logicService.getGameAnswers(this.game_id).subscribe({
      next: (data) => {
        console.log(data);
        this.game_rounds = data["game_rounds"];
        this.teams = data["teams"];
        this.team_answers = data["team_answers"];
        this.teams_results = data["teams_results"];
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  set_verdict(team, problem_id, verdict): void {
    this.logicService.setVerdict(this.game_id, team.team_hash, problem_id, verdict).subscribe({
      error: (e) => this.commonService.processError(e)
    });
    this.team_answers[problem_id][team.id].verdict = verdict;
  }

  change_points(team, problem_id): void {
    this.logicService.setPoints(this.game_id, team.team_hash, problem_id, this.team_answers[problem_id][team.id].points).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  toggle_round(round_id: any) {
    this.round_blocks[round_id] = !this.round_blocks[round_id];
  }

  calc_points(round_id: any) {
    this.logicService.calc_points(this.game_id, round_id).subscribe({
      next: (e) => this.get_answers(),
      error: (e) => this.commonService.processError(e)
    });
  }

}
