import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';
import { ImagesService } from 'src/app/services/images.service';

import { Problem } from 'src/app/models/logic/problem.model';
import { ProblemStep } from 'src/app/models/logic/problem.model';

@Component({
  selector: 'app-admin-logic-problem-edit',
  templateUrl: './admin-logic-problem-edit.component.html',
  styleUrls: ['./admin-logic-problem-edit.component.css']
})
export class AdminLogicProblemEditComponent implements OnInit {
  problem: Problem = new Problem();
  rounds: any;
  is_creating: any;

  constructor(private commonService: CommonService, private logicService: LogicService, private route: ActivatedRoute, private imagesService: ImagesService) { }

  ngOnInit(): void {
    this.is_creating = this.route.snapshot['_routerState'].url == "/admin/logic/problems/new";
    
    if (!this.is_creating) {
      this.getProblem(this.route.snapshot.params["id"]);
    }

    this.getRounds();
  }

  getProblem(id: any): void {
    this.logicService.getProblem(id).subscribe({
      next: (data) => {
        console.log(data);
        this.problem = new Problem(data["problem"]);
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  getRounds() {
    this.logicService.getRounds().subscribe({
      next: (data) => {
        this.rounds = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  trackByFn(index, item) {
    return item.name;
  }

  add_step() {
    this.problem.steps.splice(this.problem.steps.length - 1, 0, new ProblemStep())
  }

  remove_step(id: any): void {
    this.problem.steps.splice(id, 1);
  }


  // step functions
  add_problem_options(step_num) {
    if (this.problem.steps[step_num].options.length == 0) {
      this.problem.steps[step_num].options = ["", "", "", ""];
    }
  }

  change_field(step_num, field, value) {
    console.log(value);
    this.problem.steps[step_num][field] = value;
  }

  remove_problem_image(step_num, id: any): void {
    this.problem.steps[step_num].images.splice(id, 1);
  }

  add_problem_image(step_num): void {
    this.problem.steps[step_num].images.push("");
  }

  upImage(step_num, id: any): void {
    if (id != 0) {
      [
        this.problem.steps[step_num].images[id],
        this.problem.steps[step_num].images[id - 1]
      ] = [
        this.problem.steps[step_num].images[id - 1],
        this.problem.steps[step_num].images[id]
      ];
    }
  }

  downImage(step_num, id: any): void {
    if (id != this.problem.steps[step_num].images.length - 1) {
      [
        this.problem.steps[step_num].images[id],
        this.problem.steps[step_num].images[id + 1]
      ] = [
        this.problem.steps[step_num].images[id + 1],
        this.problem.steps[step_num].images[id]
      ];
    }
  }

  change_image(step_num, event, idx): void {
    this.imagesService.uploadImageByUrl(event.target.value).subscribe({
      next: (data) => {
        this.problem.steps[step_num].images[idx] = data.image_url;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  change_problem_audio(step_num, event): void {
    if (event.target.value.includes("youtube")) {
      this.imagesService.uploadVideoByUrl(event.target.value).subscribe({
        next: (data) => {
          this.problem.steps[step_num].audio_url = data.url;
        },
        error: (e) => this.commonService.processError(e)
      });
    }
  }

  change_problem_video(step_num, event): void {
    if (event.target.value.includes("youtube")) {
      this.imagesService.uploadVideoByUrl(event.target.value).subscribe({
        next: (data) => {
          this.problem.steps[step_num].video_url = data.url;
        },
        error: (e) => this.commonService.processError(e)
      });
    }
  }

  paste_image(step_num, event, idx): void {
    let items = (event.clipboardData || event.originalEvent.clipboardData).items;
    if (items) {
      let item = items[items.length - 1];
      if (item.kind === "file") {
        let blob = item.getAsFile();
        this.imagesService.uploadImage(blob).subscribe({
          next: (data) => {
            this.problem.steps[step_num].images[idx] = data.image_url;
          },
          error: (e) => this.commonService.processError(e)
        });
      }
    }
  }

  paste_video(step_num, event): void {
    let items = (event.clipboardData || event.originalEvent.clipboardData).items;
    if (items) {
      let item = items[items.length - 1];
      if (item.kind === "file") {
        let blob = item.getAsFile();
        this.imagesService.uploadImage(blob).subscribe({
          next: (data) => {
            console.log(data.image_url);
            this.problem.steps[step_num].video_url = data.image_url;
            console.log(this.problem);
          },
          error: (e) => this.commonService.processError(e)
        });
      }
    }
  }

  paste_audio(step_num, event): void {
    let items = (event.clipboardData || event.originalEvent.clipboardData).items;
    if (items) {
      let item = items[items.length - 1];
      if (item.kind === "file") {
        let blob = item.getAsFile();
        this.imagesService.uploadImage(blob).subscribe({
          next: (data) => {
            this.problem.steps[step_num].audio_url = data.image_url;
          },
          error: (e) => this.commonService.processError(e)
        });
      }
    }
  }

  submit() {
    this.logicService.saveProblem(this.problem).subscribe({
      next: (data) => window.location.href='/admin/logic/problems',
      error: (e) => this.commonService.processError(e)
    });
  }

}
