import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-admin-logic-problem-edit-media',
  templateUrl: './admin-logic-problem-edit-media.component.html',
  styleUrls: ['./admin-logic-problem-edit-media.component.css']
})
export class AdminLogicProblemEditMediaComponent implements OnInit {

  @Input() type: string = '';

  @Input() model: any = undefined;

  @Input() name: any = '';

  interval: any;
  @Input() times: any = "";
  end_time: any = 0;

  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onPaste: EventEmitter<any> = new EventEmitter();
  @Output() onUp: EventEmitter<any> = new EventEmitter();
  @Output() onDown: EventEmitter<any> = new EventEmitter();

  @Output() onChangeTimes: EventEmitter<any> = new EventEmitter();

  constructor(private renderer: Renderer2) {
    
    this.interval = setInterval(() => {
      if ((this.type == "video" || this.type == "audio") && this.model != undefined && this.model != '') {
        let player = this.renderer.selectRootElement(`#${this.name}-player`);
        
        if (player.currentTime > this.end_time) {
          player.pause();
        }
      }
    }, 100);
  }

  ngOnInit(): void {
  }

  get_title(): string {
    if (this.type == "audio") {
      return "Аудио";
    }
    if (this.type == "image") {
      return "Картинка";
    }
    if (this.type == "video") {
      return "Видео";
    }

    return "";
  }

  remove(): void {
    this.onRemove.emit();
  }

  change(event): void {
    this.onChange.emit(event);
  }

  paste(event): void {
    this.onPaste.emit(event);
  }

  up(): void {
    this.onUp.emit();
  }

  down(): void {
    this.onDown.emit();
  }

  change_times(): void {
    this.onChangeTimes.emit(this.times);
  }

  play(): void {
    let player = this.renderer.selectRootElement(`#${this.name}-player`);
    
    let range = this.times.split(';')[0]
    let parts = range.split('-')
    this.end_time = parts[1];
    player.currentTime = parts[0];
    
    player.play();
  }

}
