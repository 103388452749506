import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SantaService } from 'src/app/services/santa.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-admin-santa-games',
  templateUrl: './admin-santa-games.component.html',
  styleUrls: ['./admin-santa-games.component.css']
})
export class AdminSantaGamesComponent implements OnInit {
  games: any = [];

  constructor(private commonService: CommonService, private santaService: SantaService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getGames();
  }

  getGames() {
    this.santaService.getGames().subscribe({
      next: (data) => {
        console.log(data);
        this.games = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  start(game_id) {
    this.santaService.startGame(game_id).subscribe({
      next: (data) => {
        console.log(data);
      },
      error: (e) => this.commonService.processError(e)
    });
  }

}
