import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';

import { User } from 'src/app/models/users/user.model'


@Component({
  selector: 'app-admin-users-edit',
  templateUrl: './admin-users-edit.component.html',
  styleUrls: ['./admin-users-edit.component.css']
})
export class AdminUsersEditComponent implements OnInit {
  is_creating: boolean = false;
  user: User = new User();

  constructor(private common_service: CommonService, private user_service: UserService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.is_creating = this.route.snapshot['_routerState'].url == "/admin/users/new";
    
    if (!this.is_creating) {
      this.get_user(this.route.snapshot.params["id"]);
    }
  }

  get_user(id): void {
    this.user_service.get_user(id)
      .subscribe({
        next: (data) => {
          this.user.from_json(data);
        },
        error: (e) => this.common_service.processError(e)
      });
  }

  submit(): void {
    this.user_service.save_user(this.user)
      .subscribe({
        next: (data) => {
          window.location.href = '/admin/users';
        },
        error: (e) => this.common_service.processError(e)
      });
    console.log(this.user);
  }

}
