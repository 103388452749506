import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

/** Сервис для проверки того, что сейчас открыта одна из новых страниц */
@Injectable({ providedIn: 'root' })
export class CheckStreamingService {
  private _showStreaming: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showStreaming = this._showStreaming.asObservable();

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Костыль чтобы редизайн пророс на страницу команды
      const containsStreaming = this.router.url.includes('streaming') || this.router.url.includes('team');
      this._showStreaming.next(containsStreaming);
    });
  }

}
