import { Component, OnInit } from '@angular/core';

import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-logic-problems',
  templateUrl: './admin-logic-problems.component.html',
  styleUrls: ['./admin-logic-problems.component.css']
})

export class AdminLogicProblemsComponent implements OnInit {
  problems: any;
  rounds: any;
  round_id: any = undefined;
  pagination: any;
  page: any = undefined;

  constructor(
    private logicService: LogicService,
    private commonService: CommonService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.page = this.route.snapshot.queryParams["page"];
    
    this.getProblems();
    this.getRounds();
  }

  getProblems(): void {
    this.logicService.getProblems(this.page, this.round_id)
      .subscribe({
        next: (data) => {
          this.problems = data["problems"];
          this.pagination = data["pagination"];
        },
        error: (e) => this.commonService.processError(e)
      });
  }

  getRounds() {
    this.logicService.getRounds().subscribe({
      next: (data) => {
        this.rounds = data;
      },
        error: (e) => this.commonService.processError(e)
    });
  }

  filterRound() {
    this.getProblems();
  }

  changePage(event, page) {
    event.preventDefault();
    this.page = page;
    this.getProblems();
  }

}
