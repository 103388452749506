<div>
    <main class="content" *ngIf="page == 'not_started'" id="not_started">
        <div class="logo"></div>

        <div class="date">{{not_started.game_name}}</div>
    </main>

    <main class="content"  *ngIf="page == 'round_starting'" id="round_starting">
        <div class="logo"></div>

        <h1 class="title">
            <span class="title-count">Раунд {{round_starting.round_num}}</span>
            <span class="title-name">{{round_starting.round_name}}</span>
        </h1>
    </main>

    <main class="content" *ngIf="page == 'problem'" id="problem">
        <app-game-header
            [gameId]="gameId"
            [roundName]="header.round_name"
            [roundNumber]="header.round_num"
            [totalQuestionsCount]="header.question_count"
            [questionNum]="header.question_num"
        ></app-game-header>

        <app-logic-self-game-problem [problem]="problem" *ngIf="roundType=='Своя игра'"></app-logic-self-game-problem>
        <app-logic-hundred-to-one-problem [problem]="problem" *ngIf="roundType=='100 к 1'"></app-logic-hundred-to-one-problem>
        <app-logic-millionaire-problem [problem]="problem" *ngIf="roundType=='Кто хочет стать миллионером?'"></app-logic-millionaire-problem>
        <app-logic-intuition-problem [problem]="problem" *ngIf="roundType=='Интуиция'"></app-logic-intuition-problem>
        <app-logic-www-problem [problem]="problem" *ngIf="roundType=='Что? Где? Когда?'"></app-logic-www-problem>
        <app-logic-music-problem [problem]="problem" *ngIf="roundType=='Угадай мелодию'"></app-logic-music-problem>
        <app-logic-field-of-miracles-problem [problem]="problem" *ngIf="roundType=='Поле чудес'"></app-logic-field-of-miracles-problem>
        <app-logic-logic-problem [problem]="problem" *ngIf="roundType=='Где логика?'"></app-logic-logic-problem>
    </main>

    <main class="content"  *ngIf="page == 'teams_showing'" id="teams_showing">
        <h1 class="title">
            {{teams_showing.current}}
        </h1>
    </main>

    <main class="content" *ngIf="page == 'results'" id="results">
        <app-game-header
            [shouldShowProgress]="false"
            [shouldShowTimer]="false"
            [shouldShowPageTitle]="true"
            [pageTitle]="'Результаты'"
        ></app-game-header>
        <app-game-results
            [results]="results"
        ></app-game-results>
    </main>

</div>
