import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { VariantComponent } from '../variant/variant.component';

@Component({
  selector: 'app-rotated-card',
  templateUrl: './rotated-card.component.html',
  styleUrls: ['./rotated-card.component.css']
})
export class RotatedCardComponent implements OnInit, OnChanges {

  @Input() variant?: {
    seqNumber: string;
    text: string;
    isCorrect?: boolean;
    imgSrc?: string;
  };
  
  @Input() isPlaceholder?: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

}
