<div class="row g-3" *ngIf="model != undefined">
    <div class="row g-3">
        <div *ngIf="model != ''" class="col-sm-6">
            <video *ngIf="type == 'video' || type == 'audio'" src="{{model}}" width=300 controls id="{{name}}-player"> </video>
            <img *ngIf="type == 'image'" alt="Error" src="{{model}}" style="max-width: 150px;max-height: 150px;"/>
        </div>
        <div *ngIf="model == ''" class="col-sm-6">
            <label class="form-label" for="{{name}}">Ссылка</label>
            <input
                id="{{name}}"
                name="{{name}}"
                type="text"
                class="image_answer form-control"
                value=""
                (change)="change($event)"
                (paste)="paste($event)"
            />
        </div>

        <div class="col-sm-6">
            <div class="input-group">
            	<input name="{{name}}-times" class="form-control" id="{{name}}-times" *ngIf="type == 'video' || type == 'audio'" [(ngModel)]="times" (change)="change_times()"/>
            	<button type="button" class="close remove_image_button" aria-label="Close" (click)="up()">
                    <span aria-hidden="true">&uarr;</span>
                </button>
                <button type="button" class="close remove_image_button" aria-label="Close" (click)="down()">
                    <span aria-hidden="true">&darr;</span>
                </button>
                <button type="button" *ngIf="type == 'video' || type == 'audio'" class="close remove_answer_button" aria-label="Close" (click)="play()">
                    <span aria-hidden="true">></span>
                </button>
                <button type="button" class="close remove_answer_button" aria-label="Close" (click)="remove()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>
