<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Игры</h1>

    <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
            <a href="/admin/santa/games/new">
                <button type="button" class="btn btn-sm btn-outline-secondary">Новая игра</button>
            </a>
        </div>
    </div>
</div>

<table class="table">
    <thead>
        <tr>
            <th scope="col">Название</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let game of games">
            <td>{{game.name}}</td>
            <td>
                <button type="button" class="btn btn-sm btn-primary" style="margin: 1px;" (click)="start(game.id)"><i class="bi bi-eye"></i></button>
                <a href="/admin/santa/game/{{game.id}}/edit">
                    <button type="button" class="btn btn-sm btn-primary" style="margin: 1px;"><i class="bi bi-pencil"></i></button>
                </a>
                <a href="/admin/santa/game/{{game.id}}/delete">
                    <button type="button" class="btn btn-sm btn-primary" style="margin: 1px;"><i class="bi bi-trash"></i></button>
                </a>
            </td>
        </tr>
    </tbody>
</table>
