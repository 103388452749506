export class User  {
  id?: string;
  username?: string;
  telegram_id?: string;
  first_name?: string;
  last_name?: string;
  
  constructor() {
    this.id = undefined;
    this.username = undefined;
    this.telegram_id = undefined;
    this.first_name = undefined;
    this.last_name = undefined;
  }

  from_json(data) {
    this.id = data.id;
    this.username = data.username;
    this.telegram_id = data.telegram_id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
  }
};
