<div *ngIf="!problem.answer" class="main-question">
    <div *ngIf="problem.images?.[0]" class="main-question-image">
        <img src="{{ problem.images?.[0] }}" alt="" />
    </div>
    <div class="main-question-text"><app-scaled-text *ngIf="problem.description" [text]="problem.description"></app-scaled-text></div >
</div>

<div *ngIf="problem.answer?.teams" class="variants-container">
    <app-variant *ngFor="let item of problem.answer.teams;let i = index" [variant]="{seqNumber: (i + 1).toString(), text: item?.name + ' - ' + item?.answer }"></app-variant>
</div>
