<div>
    <div *ngIf="page == 'problem'" id="problem">
        <div id="where-logic" *ngIf="roundName">
            <span>ЙокоКвиз</span> <span> * {{roundName}} </span>
            <span> * Вопрос {{questionNum}}</span>
            <span *ngIf="seconds > 0"> * Осталось {{seconds}}</span>
        </div>

        <div class="questionText" *ngIf="roundName=='Кто хочет стать миллионером?' && problem.description">
            <span style="font-size: {{fontSize}}px;">{{problem.description}}</span>
            <br/>
            <div>
                <span style="margin: 20px; font-size: 40px">А. {{problem.options[0]}}</span>
                <span style="margin: 20px; font-size: 40px">Б. {{problem.options[1]}}</span>
                <br/>
                <span style="margin: 20px; font-size: 40px">В. {{problem.options[2]}}</span>
                <span style="margin: 20px; font-size: 40px">Г. {{problem.options[3]}}</span>
            </div>
        </div>

        <div class="questionText" *ngIf="roundName=='Поле чудес' && problem.description">
            <span style="font-size: {{fontSize}}px;">{{problem.description}}</span>
            <br/>
            <div>
                <p style="margin: 20px; font-size: 80px">{{problem.placeholder}}</p>
            </div>
        </div>

        <div class="questionText" *ngIf="roundName!='Поле чудес' && roundName!='Кто хочет стать миллионером?' && problem.description">
            <span style="font-size: {{fontSize}}px;">{{problem.description}}</span>
            <br/>
            <div>
                <p style="margin: 20px; font-size: 80px">{{problem.placeholder}}</p>
            </div>
        </div>

        <div *ngIf="!problem.description" id="images">
            <img
                *ngFor="let image of images"
                alt="Изображение не загружено"
                class="image-in-problem images{{images.length}}"
                src="{{image}}"
            />
        </div>
    </div>

    <div *ngIf="page == 'not_started'" id="not_started" class="notice">
        Игра скоро начнется, подождите немного
    </div>

    <div *ngIf="page == 'teams_showing'" id="teams_showing" class="notice">
        {{teams_showing.current}}
    </div>

    <div *ngIf="page == 'round_starting'" id="round_starting" class="notice">
        <p>Раунд {{round_starting.round_num}}</p>
        <p>{{round_starting.round_name}}</p>
    </div>

    <div *ngIf="page == 'results'" id="results" style="font-size: 46px;">
        <p>Результаты</p>
        <table border="2" class=table *ngIf="results.teams_results">
            <tr>
                <td>Команда</td>
                <td *ngFor="let game_round of results.game_rounds; let round_id = index">Раунд {{round_id + 1}}</td>
                <td>Итого</td>
            </tr>
            <tr *ngFor="let team of results.teams">
                <td>{{team.name}}</td>
                <td *ngFor="let game_round of results.game_rounds">{{results.teams_results[team.name][game_round.id]}}</td>
                <td>{{results.teams_results[team.name][0]}}</td>
            </tr>
        </table>
    </div>
</div>
