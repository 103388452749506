import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-variant",
  templateUrl: "./variant.component.html",
  styleUrls: ["./variant.component.css"],
})
export class VariantComponent implements OnInit {
  @Input()
  variant?: {
    seqNumber: string;
    text: string;
    isCorrect?: boolean;
    imgSrc?: string;
  };

  constructor() {}

  ngOnInit(): void {}
}
