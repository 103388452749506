import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logic-www-problem',
  templateUrl: './logic-www-problem.component.html',
  styleUrls: ['./logic-www-problem.component.css']
})
export class LogicWwwProblemComponent implements OnInit {

  @Input() problem: any = undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
