import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ENV } from '.env'

const baseUrl = `${ENV.apiUrl}/api/poker`;

@Injectable({
  providedIn: 'root'
})
export class PokerService {

  constructor(private http: HttpClient) { }

  getGame() {
    return this.http.get(`${baseUrl}/new/1`);
  }

  doAction(action, value) {
    return this.http.post(`${baseUrl}/action/1`, {"action": action, "value": value});
  }
}
