<div class="row g-3" *ngIf="player !== undefined">
    <div class="col-md-7 col-lg-8">
        <form class="" novalidate="" method="post">
            <h4 class="mb-3">Игрок</h4>
            <div class="row g-3">
                <div class="col-sm-12">
                    <label class="form-label" for="username">Username</label>
                    <input id="username" name="username" class="form-control" [(ngModel)]="player.username" />
                </div>
            </div>
            <br/>

            <div class="row g-3">
                <input type="button" class="btn btn-primary" value="Сохранить" (click)="submit()" />
            </div>
        </form>
    </div>
</div>
