<div class="row g-3" style="background-color: white;">
    <div class="row g-3" *ngIf="page=='new'">
        <div class="col-sm-6">
            <label class="form-label" for="teamsCount">Количество команд</label>
            <input id="teamsCount" name="teamsCount" class="form-control" [(ngModel)]="teamsCount" />
            <br/>
            <button (click)="initGame()">Продолжить</button>
        </div>
    </div>

    <div *ngIf="page=='teams_created'">
        <p *ngFor="let team of teamsInfo; let i = index">
            <a href="game/{{gameId}}/team/{{team.team_hash}}">Команда {{i + 1}} - {{team.name}}</a>
        </p>

        <button (click)="showTeams()">Показать команды на экране</button>
    </div>

    <div *ngIf="page=='teams_showing'">
        <p>{{teamsShowing.current_team.name}}</p>

        <button *ngIf="!teamsShowing.is_last" (click)="showTeams()">Следующая команда</button>
        <button *ngIf="teamsShowing.is_last" (click)="startGame()">Начать игру</button>
    </div>

    <div *ngIf="page=='round_starting'" class="col-md-12 col-lg-12">
        <h4 class="mb-3">Раунд {{data.round_num + 1}}. {{data.round_name}}</h4>
        <div>
            <button (click)="roundStart()">Начать раунд</button>
            <button (click)="nextRound()">Следующий раунд</button>
            <button (click)="prevRound()">Предыдущий раунд</button>
        </div>
    </div>

    <div *ngIf="page == 'problem'" class="col-md-12 col-lg-12">
        <h4 class="mb-3">ЙокоКвиз</h4>
        <div>
            <h5>Раунд {{data.header.round_num}}. {{data.header.round_name}}. Вопрос {{data.header.question_num}}</h5>
        </div>
        <hr>
        <div>
            <p>{{data.current_problem.description}}</p>
            <div *ngIf="data.current_problem.images">
                <img *ngFor="let image_url of data.current_problem.images" src="{{image_url}}" class="problem-image" />
            </div>
            <p *ngIf="data.current_problem.options">
                <span *ngFor="let option of data.current_problem.options; let i = index">{{i}}) {{option}} </span>
            </p>
            <video *ngIf="data.current_problem.audio_url" src="{{data.current_problem.audio_url}}" width=300 controls id="video-player"> </video>
        </div>
        <div *ngIf="data?.current_problem?.audio_url || data?.current_problem?.video_url">
            <button (click)="play()">
                play {{data?.current_problem?.range[0]}}-{{data?.current_problem?.range[1]}}
            </button>

            <button (click)="pause()">
                pause
            </button>
        </div>
        <hr>
        <div>
            <button *ngIf="!data.current_problem.has_next_step" (click)="nextQuestion()">
                Следующее задание
            </button>
            <button *ngIf="data.current_problem.has_next_step" (click)="nextStep()">
                Следующий шаг
            </button>
        </div>
        <hr>
        <div>
            <button (click)="addSeconds(30)">Дать 30 секунд</button>
            <button (click)="addSeconds(60)">Дать 60 секунд</button>
            <button (click)="addSeconds(120)">Дать 120 секунд</button>
            <br/>
        </div>
        <hr>
        <div>
            <button (click)="nextQuestion()">Следующее задание</button>
            <button (click)="prevQuestion()">Предыдущее задание</button>
            <button (click)="nextStep()">Следующий шаг</button>
            <button (click)="prevStep()">Предыдущий шаг</button>
            <br/><br/>
            <button (click)="toBeginRound()">К ответам</button>
            <button (click)="nextRound()">Следующий раунд</button>
            <button (click)="prevRound()">Предыдущий раунд</button>
            <br/><br/>
            <button (click)="show_results()">Показать результаты</button>
            <button (click)="close_results()">Скрыть результаты</button>
        </div>
        <hr>
    </div>
</div>
