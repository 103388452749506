<div class="main-question">
    <div *ngIf="problem.images?.[0] && !problem.answer" class="main-question-image">
        <img src="{{ problem.images?.[0] }}" alt="" />
    </div>
    <div *ngIf="problem.video_url && !problem.answer" class="main-question-image">
        <video src="{{problem.video_url}}" id="video-player" width="800" height="600"> </video>
    </div>
    
    <video *ngIf="problem.audio_url && !problem.answer" src="{{problem.audio_url}}" id="video-player" width="10" height="10"> </video>

    <div class="main-question-text" *ngIf="problem.description && !problem.answer">
        <app-scaled-text [hasPicture]="problem.images?.[0]" [text]="problem.description"></app-scaled-text>
    </div>

    <app-answer *ngIf="problem.answer" [problem]="problem"></app-answer>
</div>
