<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Пользователи</h1>

    <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
            <a href="/admin/users/new">
                <button type="button" class="btn btn-sm btn-outline-secondary">Новый пользователь</button>
            </a>
        </div>
    </div>
</div>

<div class="table-responsive">
    <table class="table table-striped table-sm">
        <thead>
            <tr>
                <th scope="col">Username</th>
                <th scope="col">Имя</th>
                <th scope="col">Фамилия</th>
                <th scope="col">Действия</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of users">
                <td>{{user.username}}</td>
                <td>{{user.first_name}}</td>
                <td>{{user.last_name}}</td>
                <td>
                    <a href="/admin/users/{{user.id}}/view/"><button type="button" class="btn btn-sm btn-primary"><i class="bi bi-eye"></i></button></a>
                    <a href="/admin/users/{{user.id}}/update/"><button type="button" class="btn btn-sm btn-primary"><i class="bi bi-pencil"></i></button></a>
                    <a href="/admin/users/{{user.id}}/delete/"><button type="button" class="btn btn-sm btn-primary"><i class="bi bi-trash"></i></button></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
