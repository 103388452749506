import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-admin-logic-game-edit',
  templateUrl: './admin-logic-game-edit.component.html',
  styleUrls: ['./admin-logic-game-edit.component.css']
})
export class AdminLogicGameEditComponent implements OnInit {
  rounds: any;
  game: any;
  is_creating: any;

  constructor(private commonService: CommonService, private logicService: LogicService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.is_creating = this.route.snapshot['_routerState'].url == "/admin/logic/games/new";
    
    if (this.is_creating) {
      this.game = {
        "rounds": []
      }
    } else {
      this.getGame(this.route.snapshot.params["id"]);
    }

    this.getRounds();
  }

  getRounds() {
    this.logicService.getRounds().subscribe({
      next: (data) => {
        this.rounds = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  getGame(id) {
    this.logicService.getGame(id).subscribe({
      next: (data) => {
        this.game = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  removeRound(idx) {
    this.game.rounds.splice(idx, 1);
  }

  addRound(): void {
    this.game.rounds.push({});
  }

  submit() {
    this.logicService.saveGame(this.game).subscribe({
      next: (data) => {
        window.location.href = "/admin/logic/games";
      },
      error: (e) => this.commonService.processError(e)
    });
  }

}
