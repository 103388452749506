import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';
import { timer } from 'rxjs';

import { Renderer2 } from '@angular/core';

import * as io from 'socket.io-client';
import { ENV } from '.env'

@Component({
  selector: 'app-admin-logic-game-process',
  templateUrl: './admin-logic-game-process.component.html',
  styleUrls: ['./admin-logic-game-process.component.css']
})
export class AdminLogicGameProcessComponent implements OnInit {
  gameId: number;
  teamsInfo: any = undefined;
  socket: any;

  teamsCount: any = undefined;

  newTeams: any = [];
  createdTeams: any = [];
  questions: any = [];
  teams: any = [];

  page: any;
  game: any;

  data: any;

  teamsShowing: any = {};
  currentTime: any = 0;
  
  interval;
  end_time: any = 0;
  current_delay: any = 0;
  is_playing: any = false;

  constructor(private renderer: Renderer2, private logicService: LogicService, private commonService: CommonService, private route: ActivatedRoute) {
    this.interval = setInterval(() => {
      if (this.data?.current_problem?.audio_url || this.data?.current_problem?.video_url) {
        let player = this.renderer.selectRootElement(`#video-player`);
        
        if (player.currentTime > this.end_time) {
          player.pause();

          if (this.is_playing) {
            this.current_delay += 0.1;
            if (this.current_delay >= 2) {
              this.play();
              this.current_delay = 0;
            }
          }
        }

      }
    }, 100);

    this.gameId = this.route.snapshot.params["game_id"];

    this.socket = io.connect(ENV.apiUrl);

    this.socket.on(`update_admin_page_${this.gameId}`, (data) => {
      this.updateAdminPage(data);
    });
  }

  ngOnInit(): void {
    this.getProcess();
  }

  updateAdminPage(data): void {
    console.log(data);

    this.page = data["page"];
    data = data["data"];

    if (this.page == "teams_created") {
      if (data["teams"] && data["teams"].length > 0) {
        this.teamsInfo = data["teams"];
        this.questions = data["questions"];
      }
    }

    if (this.page == "teams_showing") {
      this.teamsShowing = data;
    }
    if (this.page == "problem") {
      this.data = data;
    }
    if (this.page == "round_starting") {
      this.data = data;
    }
  }

  getProcess(): void {
    this.logicService.getGameProcessInfo(this.gameId).subscribe({
      next: (data) => {
        this.updateAdminPage(data);
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  initGame(): void {
    this.logicService.initGame(this.gameId, this.teamsCount).subscribe({
      next: (data) => {
        this.getProcess();
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  showTeams() {
    this.logicService.showTeams(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  startGame(): void {
    this.logicService.startGame(this.gameId).subscribe({
      next: (data) => {
        // this.getProcess()
      },
      error: (e) => this.commonService.processError(e)
    });
  }


  roundStart(): void {
    this.logicService.roundStart(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
    this.page = "game";
  }

  nextQuestion(): void {
    this.end_time = 0;
    this.current_delay = 0;
    this.is_playing = false;
    this.logicService.nextQuestion(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  prevQuestion(): void {
    this.end_time = 0;
    this.current_delay = 0;
    this.is_playing = false;
    this.logicService.prevQuestion(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  nextStep(): void {
    this.end_time = 0;
    this.current_delay = 0;
    this.is_playing = false;
    this.logicService.nextStep(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  prevStep(): void {
    this.end_time = 0;
    this.current_delay = 0;
    this.is_playing = false;
    this.logicService.prevStep(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  toBeginRound(): void {
    this.end_time = 0;
    this.current_delay = 0;
    this.is_playing = false;
    this.logicService.toBeginRound(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  nextRound(): void {
    this.end_time = 0;
    this.current_delay = 0;
    this.is_playing = false;
    this.logicService.nextRound(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
    this.page = "game";
  }

  prevRound(): void {
    this.end_time = 0;
    this.current_delay = 0;
    this.is_playing = false;
    this.logicService.prevRound(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
    this.page = "game";
  }

  show_results() {
    this.logicService.show_results(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  close_results() {
    this.logicService.close_results(this.gameId).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  addSeconds(seconds): void {
    this.logicService.changeSeconds(this.gameId, seconds).subscribe({
      error: (e) => this.commonService.processError(e)
    });
  }

  play() {
    this.is_playing = true;
    let player = this.renderer.selectRootElement(`#video-player`);
    
    player.currentTime = this.data.current_problem.range[0];
    this.end_time = this.data.current_problem.range[1];

    player.play();

    // this.logicService.play_media(this.gameId).subscribe({
    //   error: (e) => this.commonService.processError(e)
    // });
  }

  pause() {
    this.is_playing = false;
    let player = this.renderer.selectRootElement(`#video-player`);

    player.pause();

    // this.logicService.pause_media(this.gameId).subscribe({
    //   error: (e) => this.commonService.processError(e)
    // });
  }

}
