<div class="row g-3" *ngIf="game !== undefined">
    <div class="col-md-7 col-lg-8">
        <h4 class="mb-3">Данные о игре</h4>

        <form action="" method="post">
            <div class="row g-3">
                <div class="col-sm-6">
                    <label class="form-label" for="name">Название</label>
                    <input id="name" name="name" class="form-control" value="{{game.name}}" [(ngModel)]="game.name" />
                </div>
                <div class="col-sm-6">
                    <label class="form-label" for="description">Описание</label>
                    <input id="description" name="description" class="form-control" [(ngModel)]="game.description" />
                </div>
            </div>
            <hr class="my-4">
            <div *ngFor="let _ of game.rounds; let i = index" class="round row g-3">
                <div class="col-sm-4">
                    <label class="form-label" for="round_type_{{i}}">Раунд</label>
                    <select class="form-select" id="round_type_{{i}}" name="round_type_{{i}}" [(ngModel)]="game.rounds[i].logic_round_id">
                        <option *ngFor="let round of rounds" value="{{round.id}}">{{round.name}}</option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <label class="form-label" for="priority_{{i}}">Свое название</label>
                    
                    <div class="input-group">
                        <input id="round_name_{{i}}" name="round_name_{{i}}" class="form-control" [(ngModel)]="game.rounds[i].name"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label class="form-label" for="priority_{{i}}">Номер раунда</label>
                    
                    <div class="input-group">
                        <input id="priority_{{i}}" name="priority_{{i}}" class="form-control" [(ngModel)]="game.rounds[i].priority"/>
                        <button type="button" class="close" aria-label="Close" (click)="removeRound(i)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <hr class="my-4">
            <p> <input type="button" id="add_round_button" value="Добавить раунд" (click)="addRound()" /> </p>
            <p> <input type="button" id="submit" value="Сохранить" (click)="submit()" /> </p>
        </form>
    </div>
</div>

