import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { timer } from 'rxjs';
import * as io from 'socket.io-client';
import { ENV } from '.env'


@Component({
  selector: 'app-game-header',
  templateUrl: './game-header.component.html',
  styleUrls: ['./game-header.component.css']
})
export class GameHeaderComponent implements OnInit {
  socket: any;
  seconds: number = 0;

  @Input()
  gameId: number = 0;

  @Input()
  shouldShowProgress: boolean = true;
  @Input()
  shouldShowTimer: boolean = true;
  @Input()
  shouldShowPageTitle: boolean = false;
  @Input()
  pageTitle: string = '';

  @Input()
  roundName: string = '';
  @Input()
  roundNumber: number = 0;

  @Input()
  questionNum: number = 1;
  @Input()
  totalQuestionsCount: number = 0;

  fontSize: any = undefined;

  constructor() {
  }

  ngOnInit(): void {
    this.socket = io.connect(ENV.apiUrl);
    this.socket.on(`change_seconds_${this.gameId}`, (data) => {
      this.seconds = data["seconds"];
    });
    timer(1000, 1000).subscribe(seconds => {
      if (this.seconds > 0) {
        this.seconds -= 1;
      }
    });
  }

  get_time(num): string {
    if (num >= 10) {
      return num.toString();
    }

    return "0" + num.toString();
  }

  get_minutes(): string {
    return this.get_time(Math.floor(this.seconds / 60));
  }

  get_seconds(): string {
    return this.get_time(this.seconds % 60);
  }
}
