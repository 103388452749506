<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Раунды</h1>
    <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
            <a href="/admin/logic/rounds/new">
                <button type="button" class="btn btn-sm btn-outline-secondary">Новый раунд</button>
            </a>
        </div>
    </div>
</div>
<table class="table">
    <thead>
        <tr>
            <th scope="col">Название</th>
            <th scope="col">Описание</th>
            <th scope="col">Тип раунда</th>
            <th scope="col">Действия</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let round of rounds; let i = index">
            <td>{{round.name}}</td>
            <td>{{round.description}}</td>
            <td>{{round.game_type}}</td>
            <td>
                <a href="/admin/logic/round/{{round.id}}/view/"><button type="button" class="btn btn-sm btn-primary">
                	<i class="bi bi-eye"></i></button>
                </a>
                <a href="/admin/logic/round/{{round.id}}/edit/">
                	<button type="button" class="btn btn-sm btn-primary"><i class="bi bi-pencil"></i></button>
                </a>
                <button type="button" class="btn btn-sm btn-primary" (click)="removeRound(i)">
                    <i class="bi bi-trash"></i>
                </button>
            </td>
        </tr>
    </tbody>
</table>
