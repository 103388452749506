import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { SantaService } from 'src/app/services/santa.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-admin-santa-game',
  templateUrl: './admin-santa-game.component.html',
  styleUrls: ['./admin-santa-game.component.css']
})
export class AdminSantaGameComponent implements OnInit {
  game: any = undefined;
  is_creating: any;


  constructor(private commonService: CommonService, private santaService: SantaService, private route: ActivatedRoute) { }

  
  ngOnInit(): void {
    this.is_creating = this.route.snapshot['_routerState'].url == "/admin/santa/games/new";
    
    if (this.is_creating) {
      this.game = {
        "name": "",
        "info": ""
      }
    } else {
      this.getGame(this.route.snapshot.params["id"]);
    }
  }

  getGame(id: any): void {
    this.santaService.getGame(id).subscribe({
      next: (data) => {
        console.log(data);
        this.game = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  submit() {
    this.santaService.saveGame(this.game).subscribe({
      next: (data) => {
        console.log(data);
      },
      error: (e) => this.commonService.processError(e)
    });
  }

}
