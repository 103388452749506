<section class="vh-100" style="background-color: #508bfc;">
	<div class="container py-5 h-100">
		<div class="row d-flex justify-content-center align-items-center h-100">
			<div class="col-12 col-md-8 col-lg-6 col-xl-5">
				<div class="card shadow-2-strong" style="border-radius: 1rem;">
					<div class="card-body p-5 text-center">
            			<h3 class="mb-5">Вход</h3>
            			<form [formGroup]="form" (submit)="submit($event)">
	            			<div class="form-outline mb-4">
	              				<label class="form-label" for="username">Логин</label>
	              				<input id="username" class="form-control form-control-lg" formControlName="username" />
	            			</div>

	            			<div class="form-outline mb-4">
	              				<label class="form-label" for="password">Пароль</label>
	              				<input type="password" id="password" class="form-control form-control-lg" formControlName="password" />
	            			</div>

	            			<div class="form-check d-flex justify-content-start mb-4">
	              				<input class="form-check-input" type="checkbox" value="" id="remember_me" formControlName="remember_me" />
	              				<label class="form-check-label" for="remember_me">Запомнить меня</label>
	            			</div>

	            			<button class="btn btn-primary btn-lg btn-block" type="submit">Войти</button>
	            		</form>
          			</div>
        		</div>
      		</div>
    	</div>
  	</div>
</section>
