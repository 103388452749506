import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-logic-intuition-problem",
  templateUrl: "./logic-intuition-problem.component.html",
  styleUrls: ["./logic-intuition-problem.component.css"],
})
export class LogicIntuitionProblemComponent implements OnInit {
  @Input() problem: any = undefined;

  constructor() {}

  ngOnInit(): void {
  }

  _trackBy(_index, item) {
    return item?.name;
  }
}
