import { Component, OnInit } from '@angular/core';
import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-logic-game-round-view',
  templateUrl: './admin-logic-game-round-view.component.html',
  styleUrls: ['./admin-logic-game-round-view.component.css']
})
export class AdminLogicGameRoundViewComponent implements OnInit {
  problems: any;
  game_id: any;
  round_id: any;

  constructor(private commonService: CommonService, private logicService: LogicService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.game_id = this.route.snapshot.params["game_id"];
    this.round_id = this.route.snapshot.params["round_id"];
    this.getGameRound();
  }

  getGameRound() {
    this.logicService.getGameRound(this.game_id, this.round_id).subscribe({
      next: (data) => {
        console.log(data);
        this.problems = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  toggleProblem(idx) {
    this.logicService.toggleProblem(this.game_id, this.round_id, this.problems[idx].id, this.problems[idx].checked).subscribe({
      next: (data) => {},
      error: (e) => this.commonService.processError(e)
    });
  }

}
