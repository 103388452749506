import { Component, OnInit } from '@angular/core';

import { Problem } from 'src/app/models/logic/problem.model';
import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';

import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-admin-logic-problem-view',
  templateUrl: './admin-logic-problem-view.component.html',
  styleUrls: ['./admin-logic-problem-view.component.css']
})
export class AdminLogicProblemViewComponent implements OnInit {

  problem: Problem = new Problem();

  constructor(
    private commonService: CommonService, 
    private logicService: LogicService,
    private route: ActivatedRoute,
    private router: Router) {}

  ngOnInit(): void {
    this.getProblem(this.route.snapshot.params["id"]);
  }

  getProblem(id: string): void {
    this.logicService.getProblem(id)
      .subscribe({
        next: (data) => {
          this.problem = data;
          console.log(data);
        },
        error: (e) => this.commonService.processError(e)
      });
  }

}
