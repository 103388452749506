import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Problem } from '../models/logic/problem.model';

import { CookieService } from 'ngx-cookie-service';

import { ENV } from '.env'

const baseUrl = `${ENV.apiUrl}/api/admin/logic`;
const basePlayingUrl = `${ENV.apiUrl}/api/logic`;

@Injectable({
  providedIn: 'root'
})
export class LogicService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getHeaders() {
    let headers = new HttpHeaders();
    let token = this.cookieService.get("auth_token");

    return headers.set("Authorization", `Bearer ${token}`);
  }

  getProblems(page: any, roundId: any = undefined) {
    let cgi: Array<string> = [];
    if (page !== undefined) {
      cgi.push(`page=${page}`);
    }
    if (roundId !== undefined) {
      cgi.push(`round_id=${roundId}`);
    }
    let url = `${baseUrl}/problems`;
    if (cgi.length > 0) {
      url = `${url}?${cgi.join("&")}`;
    }

    return this.http.get(url, {headers: this.getHeaders()});
  }

  getProblem(id: any): Observable<Problem> {
    return this.http.get<Problem>(`${baseUrl}/problem/${id}`, {headers: this.getHeaders()});
  }

  saveProblem(problem) {
    let url = `${baseUrl}/problems/new`;
    if (problem.id !== undefined) {
      url = `${baseUrl}/problem/${problem.id}/edit`;
    }
    problem.answer_step = problem.steps[problem.steps.length - 1];
    problem.steps.splice(problem.steps.length - 1, 1);
    return this.http.post<any>(url, problem, {headers: this.getHeaders()});
  }

  getRounds() {
    return this.http.get(`${baseUrl}/rounds`, {headers: this.getHeaders()});
  }

  getRound(id) {
    return this.http.get(`${baseUrl}/round/${id}`, {headers: this.getHeaders()});
  }

  saveRound(logicRound) {
    let url = `${baseUrl}/rounds/new`;
    if (logicRound.id !== undefined) {
      url = `${baseUrl}/round/${logicRound.id}/edit`;
    }
    return this.http.post(url, logicRound, {headers: this.getHeaders()});
  }

  getRoundTypes() {
    return this.http.get(`${baseUrl}/round-types`, {headers: this.getHeaders()});
  }

  getGames() {
    return this.http.get(`${baseUrl}/games`, {headers: this.getHeaders()});
  }

  getGame(id) {
    return this.http.get(`${baseUrl}/game/${id}`, {headers: this.getHeaders()});
  }

  saveGame(game) {
    let url = `${baseUrl}/games/new`;
    if (game.id !== undefined) {
      url = `${baseUrl}/game/${game.id}/edit`;
    }
    return this.http.post<any>(url, game, {headers: this.getHeaders()});
  }

  normalizeRound(game_id, round_id) {
    return this.http.post(`${baseUrl}/game/${game_id}/round/${round_id}/normalize`, {}, {headers: this.getHeaders()});
  }

  changePriority(game_id, round_id, problem_id, priority) {
    return this.http.post(
      `${baseUrl}/game/${game_id}/round/${round_id}/problem/${problem_id}/set_priority`,
      {"priority": priority},
      {headers: this.getHeaders()}
    );
  }

  changePoints(game_id, round_id, problem_id, points) {
    return this.http.post(
      `${baseUrl}/game/${game_id}/round/${round_id}/problem/${problem_id}/set_points`,
      {"points": points},
      {headers: this.getHeaders()}
    );
  }

  getGameRound(gameId, roundId) {
    return this.http.get(`${baseUrl}/game/${gameId}/round/${roundId}`, {headers: this.getHeaders()});
  }

  toggleProblem(game_id, round_id, problem_id, value) {
    return this.http.post(
      `${baseUrl}/game/${game_id}/round/${round_id}/problem/${problem_id}/toggle`,
      {"is_adding": value},
      {headers: this.getHeaders()}
    );
  }

  // playing
  saveTeams(gameId, teams) {
    return this.http.post(`${basePlayingUrl}/game/${gameId}/start`, teams, {headers: this.getHeaders()});
  }

  getRoundForGame(gameId, roundId) {
    return this.http.get(`${basePlayingUrl}/game/${gameId}/round/${roundId}`, {headers: this.getHeaders()});
  }

  getProblemForGame(gameId, roundId, problemId, imagesNum) {
    let path = `${basePlayingUrl}/game/${gameId}/round/${roundId}/problem/${problemId}`;
    if (imagesNum != null) {
      path = `${path}?images_num=${imagesNum}`;
    }
    return this.http.get(path, {headers: this.getHeaders()});
  }

  getProblemAnswerForGame(gameId, roundId, problemId) {
    let path = `${basePlayingUrl}/game/${gameId}/round/${roundId}/problem/${problemId}`;
    return this.http.get(path, {headers: this.getHeaders()});
  }

  cancelTeam(gameId, teamId) {
    return this.http.post(`${basePlayingUrl}/game/${gameId}/team/${teamId}/wrong_answer`, {}, {headers: this.getHeaders()});
  }

  getGuestData(gameId) {
    return this.http.get(`${basePlayingUrl}/game/${gameId}/guest`, {headers: this.getHeaders()});
  }

  getStreamData(gameId) {
    return this.http.get(`${basePlayingUrl}/game/${gameId}/stream`, {headers: this.getHeaders()});
  }

  showImages(gameId) {
    return this.http.post(`${basePlayingUrl}/game/${gameId}/show_images`, {}, {headers: this.getHeaders()});
  }

  setReadyStatus(gameId, teamId) {
    return this.http.post(`${basePlayingUrl}/game/${gameId}/team/${teamId}/ready`, {}, {headers: this.getHeaders()});
  }

  getGameProcessInfo(gameId) {
    return this.http.get(`${baseUrl}/game/${gameId}/process_info`, {headers: this.getHeaders()});
  }

  getGameAnswers(gameId) {
    return this.http.get(`${baseUrl}/game/${gameId}/answers`, {headers: this.getHeaders()});
  }

  getTeamPage(gameId, teamId) {
    return this.http.get(`${basePlayingUrl}/game/${gameId}/team/${teamId}/page`, {headers: this.getHeaders()});
  }

  setTeamAnswer(gameId, teamId, answer_id, answer) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/team/${teamId}/set_answer`,
      {"answer_id": answer_id, "answer": answer},
      {headers: this.getHeaders()}
    );
  }

  setTeamName(gameId, teamId, name) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/team/${teamId}/set_name`,
      {"name": name},
      {headers: this.getHeaders()}
    );
  }

  startGame(gameId) {
    return this.http.post(`${basePlayingUrl}/game/${gameId}/start_game`, {}, {headers: this.getHeaders()});
  }

  nextQuestion(gameId) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/next_question`,
      {},
      {headers: this.getHeaders()}
    );
  }

  prevQuestion(gameId) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/prev_question`,
      {},
      {headers: this.getHeaders()}
    );
  }

  nextStep(gameId) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/next_step`,
      {},
      {headers: this.getHeaders()}
    );
  }

  prevStep(gameId) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/prev_step`,
      {},
      {headers: this.getHeaders()}
    );
  }

  roundStart(gameId) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/round_start`,
      {},
      {headers: this.getHeaders()}
    );
  }

  toBeginRound(gameId) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/to_begin_round`,
      {},
      {headers: this.getHeaders()}
    );
  }

  nextRound(gameId) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/next_round`,
      {},
      {headers: this.getHeaders()}
    );
  }

  prevRound(gameId) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/prev_round`,
      {},
      {headers: this.getHeaders()}
    );
  }

  setVerdict(gameId, teamHash, problemId, verdict) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/set_verdict`,
      {"team_id": teamHash, "answer_id": problemId, "verdict": verdict},
      {headers: this.getHeaders()}
    );
  }

  setPoints(gameId, teamId, problemId, points) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/set_points`,
      {"team_id": teamId, "problem_id": problemId, "points": points},
      {headers: this.getHeaders()}
    );
  }

  changeSeconds(gameId, seconds) {
    return this.http.post(
      `${basePlayingUrl}/game/${gameId}/change_seconds`,
      {"seconds": seconds},
      {headers: this.getHeaders()}
    );
  }

  initGame(gameId, teamsCount) {
    return this.http.post(`${basePlayingUrl}/game/${gameId}/init`, {"teams_count": teamsCount}, {headers: this.getHeaders()});
  }

  clearGame(gameId) {
    return this.http.post(`${basePlayingUrl}/game/${gameId}/clear`, {}, {headers: this.getHeaders()});
  }

  showTeams(gameId) {
    return this.http.post(`${basePlayingUrl}/game/${gameId}/show_teams`, {}, {headers: this.getHeaders()});
  }

  show_results(game_id) {
    return this.http.post(`${baseUrl}/game/${game_id}/show_results`, {}, {headers: this.getHeaders()});
  }

  close_results(game_id) {
    return this.http.post(`${baseUrl}/game/${game_id}/close_results`, {}, {headers: this.getHeaders()});
  }

  calc_points(game_id, round_id) {
    return this.http.post(`${baseUrl}/game/${game_id}/calc_points`, {"round_id": round_id}, {headers: this.getHeaders()});
  }

  play_media(game_id) {
    return this.http.post(`${basePlayingUrl}/game/${game_id}/play_media`, {}, {headers: this.getHeaders()});
  }

  pause_media(game_id) {
    return this.http.post(`${basePlayingUrl}/game/${game_id}/pause_media`, {}, {headers: this.getHeaders()});
  }

}
