import { Component, OnInit } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-users-index',
  templateUrl: './admin-users-index.component.html',
  styleUrls: ['./admin-users-index.component.css']
})
export class AdminUsersIndexComponent implements OnInit {
  users: any = undefined;

  constructor(private common_service: CommonService, private user_service: UserService) { }

  ngOnInit(): void {
    this.get_users();
  }

  get_users(): void {
    this.user_service.get_users()
      .subscribe({
        next: (data) => {
          console.log(data);
          this.users = data;
        },
        error: (e) => this.common_service.processError(e)
      });
  }

}
