<div *ngIf="canBeShowed" id="images">
    <img
        *ngFor="let image of images"
        alt="Изображение не загружено"
        src="{{image}}"
        class="image-in-problem images{{images.length}}"
    />
</div>

<div *ngIf="seconds > 0 && !canBeShowed" id="content">
    <p class="timer" id="timer">{{seconds}}</p>
</div>
