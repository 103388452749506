<div class="row g-3" *ngIf="problem !== undefined">
    <div class="col-md-7 col-lg-8">
        <form class="" novalidate="" method="post">
            <h4 class="mb-3">Общая информация</h4>
            <div class="row g-3">
                <div class="col-sm-6">
                    <label class="form-label" for="name">Название</label>
                    <input id="name" name="name" class="form-control" value="{{problem.name}}" [(ngModel)]="problem.name" />
                </div>
                <div class="col-sm-6">
                    <label class="form-label" for="round_type">Раунд</label>
                    <select class="form-select" id="round_type" name="round_type" [(ngModel)]="problem.round_id">
                        <option *ngFor="let round of rounds" value="{{round.id}}">{{round.name}}</option>
                    </select>
                </div>
            </div>
            <hr class="my-4">

            <button (click)="add_step()">Добавить шаг</button>

            <div *ngFor="let step of problem.steps; trackBy: trackByFn; let step_num = index">
                <h4 *ngIf="!step.is_answer_step" class="mb-3">Шаг {{step_num + 1}}<button (click)=remove_step(step_num)>X</button></h4>
                <h4 *ngIf="step.is_answer_step" class="mb-3">Ответ</h4>

                <div class="row g-3">
                    <div class="col-sm-12">
                        <label for="description" class="form-label">Текст</label>
                        <textarea
                            class="form-control"
                            id="description"
                            name="description-{{step_num}}"
                            rows="3"
                            [(ngModel)]="problem.steps[step_num].description"
                        >
                        </textarea>
                    </div>
                </div>

                <div class="row g-3">
                    <div class="col-sm-12">
                        <br/>
                        Добавить:
                        <button (click)="add_problem_image(step_num)">Картинку</button>
                        <button (click)="change_field(step_num, 'audio_url', '')">Аудио</button>
                        <button (click)="change_field(step_num, 'video_url', '')">Видео</button>
                        <button (click)="change_field(step_num, 'options', ['','','',''])">Варианты ответов</button>
                        <button (click)="change_field(step_num, 'letters', '')">Буквы-подсказки</button>
                        <br/><br/>
                    </div>
                </div>

                <div class="row g-3" *ngIf="step.options.length != 0">
                    <h6 class="mb-3">Варианты ответов <button (click)="change_field(step_num, 'options', [])">X</button></h6>
                    <div class="col-sm-12">
                        <p *ngFor="let option of [].constructor(step.options.length); let option_num = index">
                            <input
                                id="option-{{step_num}}-{{option_num}}"
                                name="option-{{step_num}}-{{option_num}}"
                                class="form-control"
                                [(ngModel)]="problem.steps[step_num].options[option_num]"
                            />
                        </p>
                    </div>
                </div>

                <div class="row g-3" *ngIf="problem.steps[step_num].letters != undefined">
                    <h6 class="mb-3">Буквы для поле чудес <button (click)="change_field(step_num, 'letters', undefined)">X</button></h6>
                    <div class="col-sm-12">
                        <input name="letters-{{step_num}}" class="form-control" [(ngModel)]="problem.steps[step_num].letters"/>
                    </div>
                </div>

                 <div class="row g-3" *ngIf="problem.steps[step_num].audio_url != undefined">
                    <h5 class="mb-3">Аудио</h5>
                    <app-admin-logic-problem-edit-media
                        type="audio"
                        model={{problem.steps[step_num].audio_url}}
                        name="problem_audio-{{step_num}}"
                        times="{{problem.steps[step_num].audio_times}}"
                        (onRemove)="change_field(step_num, 'audio_url', undefined)"
                        (onChange)="change_problem_audio(step_num, $event)"
                        (onPaste)="paste_audio(step_num, $event)"
                        (onChangeTimes)="change_field(step_num, 'audio_times', $event)"
                    ></app-admin-logic-problem-edit-media>
                </div>

                <div class="row g-3" *ngIf="step.video_url != undefined">
                    <h5 class="mb-3">Видео</h5>
                    <app-admin-logic-problem-edit-media
                        type="video"
                        model={{problem.steps[step_num].video_url}}
                        name="problem_video-{{step_num}}"
                        times="{{problem.steps[step_num].video_times}}"
                        (onRemove)="change_field(step_num, 'video_url', undefined)"
                        (onChange)="change_problem_video(step_num, $event)"
                        (onPaste)="paste_video(step_num, $event)"
                        (onChangeTimes)="change_field(step_num, 'video_times', $event)"
                    ></app-admin-logic-problem-edit-media>
                </div>

                <div class="row g-3" *ngIf="step.images.length > 0">
                    <h5 class="mb-3">Картинки для задания</h5>
                    <div *ngFor="let _ of [].constructor(problem.steps[step_num].images.length); let image_num = index" class="row g-3">
                        <app-admin-logic-problem-edit-media
                            type="image"
                            model={{problem.steps[step_num].images[image_num]}}
                            name="problem_image-{{step_num}}_{{image_num}}"
                            (onRemove)="remove_problem_image(step_num, image_num)"
                            (onChange)="change_image(step_num, $event, image_num)"
                            (onPaste)="paste_image(step_num, $event, image_num)"
                            (onUp)="upImage(step_num, image_num)"
                            (onDown)="downImage(step_num, image_num)"
                        ></app-admin-logic-problem-edit-media>
                    </div>
                </div>

                <hr class="my-4">
            </div>
            <div class="row g-3">
                <button class="btn btn-primary" (click)="submit()">Сохранить</button>
            </div>
        </form>
    </div>
</div>
