<header class="header" style="--progress: {{questionNum}}/{{totalQuestionsCount}}">
    <div class="header-logo" ></div>
    <div class="header-center">
        <ng-container *ngIf="shouldShowProgress">
            <div class="header-question">Вопрос {{questionNum}}/{{totalQuestionsCount}}</div>
            <div class="header-progress"></div>
            <div class="header-round">
                <span>Раунд {{roundNumber}}</span>
                <span>{{roundName}}</span>
            </div>
        </ng-container>
        <div *ngIf="shouldShowPageTitle" class="header-page-title">
            {{pageTitle}}
        </div>
    </div>
    <div *ngIf="shouldShowTimer" class="header-timer">{{get_minutes()}}:{{get_seconds()}}</div>
</header>
