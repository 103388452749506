import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from 'src/app/services/common.service';
import { GameStatisticsService } from 'src/app/services/game-statistics.service';

@Component({
  selector: 'app-admin-game-statistics-players-edit',
  templateUrl: './admin-game-statistics-players-edit.component.html',
  styleUrls: ['./admin-game-statistics-players-edit.component.css']
})
export class AdminGameStatisticsPlayersEditComponent implements OnInit {
  player: any = {};
  game_id: any = undefined;

  constructor(private common_service: CommonService, private game_statistics_service: GameStatisticsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.game_id = this.route.snapshot.params["game_id"];
  }

  get_player(id): void {
    // this.user_service.get_user(id)
    //   .subscribe({
    //     next: (data) => {
    //       this.user.from_json(data);
    //     },
    //     error: (e) => this.common_service.processError(e)
    //   });
  }

  submit(): void {
    this.game_statistics_service.save_player(this.game_id, this.player)
      .subscribe({
        next: (data) => {
          window.location.href = `/admin/game-statistics/${this.game_id}/players`;
        },
        error: (e) => this.common_service.processError(e)
      });
  }

}
