import { Component, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-logic-images-block',
  templateUrl: './logic-images-block.component.html',
  styleUrls: ['./logic-images-block.component.css']
})
export class LogicImagesBlockComponent implements OnInit {
  @Input() images: Array<string> = [];
  @Input() canBeShowed: boolean = false;
  seconds: number;
  delay: number = 1;

  constructor() {
    this.seconds = this.delay;

    timer(1000, 1000).subscribe(seconds => {
      if (seconds <= this.delay) {
        this.seconds = this.delay - seconds;
      }
    });
  }

  ngOnInit(): void {
  }

}
