import {ActivatedRoute} from '@angular/router';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LogicService} from 'src/app/services/logic.service';

import * as io from 'socket.io-client';
import {ENV} from '.env'
import {FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-logic-game-team',
  templateUrl: './logic-game-team.component.html',
  styleUrls: ['./logic-game-team.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LogicGameTeamComponent implements OnInit {
  answers: {
    /** Ответ отображаемый в строке ввода */
    current: string;
    /** Ответ который сохранён на сервере */
    set: string;
    /** Заблокировать изменение поля, будет отображаться ответ и з поля set */
    disabled: boolean;
  }[] = [];
  gameId: any;
  teamId: any;
  socket: any;
  /** Название раунда */
  roundName: string = '';
  /** Номер раунда */
  roundNum: string | undefined;
  /** Данные про количество раундов */
  question: {
    /** Всего вопросов */
    count: number;
    /** Номер текущего вопроса */
    current: number;
  } = {count: 0, current: 0}

  teamName: any = {
    /** Отображаемое в поле ввода */
    current: "",
    /** Сохраненное на сервере, используется для отображения */
    set: ""
  };
  page: any = undefined;

  constructor(private logicService: LogicService, private route: ActivatedRoute) {
    this.gameId = this.route.snapshot.params["game_id"];
    this.teamId = this.route.snapshot.params["team_id"];

    this.socket = io.connect(ENV.apiUrl);

    this.socket.on(`new_round_${this.gameId}`, (data) => {
      this.getTeamPage();
    });

    this.socket.on(`update_team_page_${this.gameId}_${this.teamId}`, (data) => {
      this.getTeamPage();
    });

    this.socket.on(`update_team_page_states_${this.gameId}_${this.teamId}`, (data) => {
      this.getTeamPage(true);
    });

  }

  ngOnInit(): void {
    this.getTeamPage();
  }

  updateTeamData(data, only_states = false) {
    console.log(data);
    this.page = data.page;
    const pageData = data?.data;

    // Название команды проставляем всегда
    if (pageData?.team || pageData?.name) {
      this.teamName.set = pageData?.team || pageData?.name;
    } else {
      this.teamName.set = '';
    }

    if (pageData?.header) {
      if (pageData.header?.round_name) {
        this.roundName = pageData.header.round_name || '';
      }
      if (pageData.header?.round_num) {
        this.roundNum = pageData.header.round_num || '';
      }
    } else {
      // если нет обнуляем на всякий случай
      this.roundName = '';
      this.roundNum = '';
    }

    if (this.page == "name_entering") {
      if (!this.teamName.current && this.teamName.set) {
        this.teamName.current = this.teamName.set;
      }
    } else if (this.page === 'not_started') {
      // Пока что ничего не нужно делать, оставил пустой иф, чтобы не забыть
    } else if (this.page == "answers_entering") {
      if (!only_states) {
        this.answers = []; // обнуляем текущий массив
        if (pageData?.answers?.length !== 0) {
          pageData.answers.forEach(item => {

            this.answers.push({
              current: item.text,
              set: item.text,
              disabled: Boolean(item.disabled),
            })
          })
        }
      } else {
        for (let i = 0; i < (pageData?.answers?.length || 0); i++) {
          let item = pageData.answers[i];
          this.answers[i].disabled = item.disabled;
        }
      }
    }
  }

  getTeamPage(only_states = false) {
    this.logicService.getTeamPage(this.gameId, this.teamId).subscribe({
      next: (data: any) => {
        this.updateTeamData(data, only_states);
      },
      error: (e) => console.error(e)
    });
  }

  setAnswer(idx: any): void {
    this.logicService.setTeamAnswer(this.gameId, this.teamId, idx, this.answers[idx].current).subscribe({
      next: (data: any) => {
        if (data["status"] == "ok") {
          this.answers[idx].set = data["answer"];
          this.getTeamPage(true);
        }
      },
      error: (e) => console.error(e)
    });
  }

  setTeamName() {
    this.logicService.setTeamName(this.gameId, this.teamId, this.teamName.current).subscribe({
      next: (data: any) => {
        this.teamName.set = data["name"];
      },
      error: (e) => console.error(e)
    });
  }

}
