import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GameStatisticsService } from 'src/app/services/game-statistics.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-admin-game-statistics-games',
  templateUrl: './admin-game-statistics-games.component.html',
  styleUrls: ['./admin-game-statistics-games.component.css']
})
export class AdminGameStatisticsGamesComponent implements OnInit {
  games: any = undefined;

  constructor(private commonService: CommonService, private gameStatisticsService: GameStatisticsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.gameStatisticsService.getGames().subscribe({
      next: (data) => {
        console.log(data);
        this.games = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

}
