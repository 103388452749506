import { Component, OnInit } from '@angular/core';
import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-logic-game-view',
  templateUrl: './admin-logic-game-view.component.html',
  styleUrls: ['./admin-logic-game-view.component.css']
})
export class AdminLogicGameViewComponent implements OnInit {
  game: any;
  round_showed: any = {};

  constructor(private commonService: CommonService, private logicService: LogicService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getGame(this.route.snapshot.params["id"]);
  }

  getGame(id) {
    this.logicService.getGame(id).subscribe({
      next: (data) => {
        console.log(data);
        this.game = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  toggleRound(idx) {
    this.round_showed[idx] = this.round_showed[idx] === undefined ? true : undefined;
  }

  normalizeRound(game_id, round_id) {
    this.logicService.normalizeRound(game_id, round_id).subscribe({
      next: (data) => {
        this.game = data;
      },
      error: (e) => this.commonService.processError(e)
    });
  }

  changePriority(game_id, round_id, event, game_problem_id) {
    this.logicService.changePriority(game_id, round_id, game_problem_id, event.target.value).subscribe({
      next: (data) => {},
      error: (e) => this.commonService.processError(e)
    });
  }

  changePoints(game_id, round_id, event, game_problem_id) {
    this.logicService.changePoints(game_id, round_id, game_problem_id, event.target.value).subscribe({
      next: (data) => {},
      error: (e) => this.commonService.processError(e)
    });
  }

}
