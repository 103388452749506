import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { UserService } from 'src/app/services/user.service';

import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;

  constructor(private userService: UserService, private cookieService: CookieService) {
    this.form = new FormGroup({
      "username": new FormControl("", Validators.required),
      "password": new FormControl("", Validators.required),
      "remember_me": new FormControl("") 
    });
  }

  ngOnInit(): void { }

  submit(event): void {
    this.userService.login(this.form.value).subscribe({
      next: (data) => {
        this.cookieService.set("auth_token", data["token"]);
        window.location.href='/admin';
      },
      error: (e) => console.error(e)
    });
    event.preventDefault();
  }
}
