<div class="row g-3" style="background-color: white;">
    <div *ngIf="game_rounds" class="col-md-12 col-lg-12">
        <div *ngFor="let game_round of game_rounds; let round_num = index">
            <p>
                {{game_round.name}}
                <button (click)="toggle_round(round_num)">Свернуть</button>
                <button (click)="calc_points(round_num)">Посчитать баллы</button>
            </p>
            <table border="2" class=table *ngIf="round_blocks[round_num]">
                <tr>
                    <td>#</td>
                    <td>Название</td>
                    <td>Ответ</td>
                    <td *ngFor="let team of teams; let team_num = index">{{team.name}}</td>
                </tr>
                <tr *ngFor="let problem of game_round.problems; let problem_num = index">
                    <td>{{problem_num + 1}}</td>
                    <td>{{problem.name}}</td>
                    <td>{{problem.answer}}</td>
                    <td *ngFor="let team of teams; let team_num = index">
                        <span class="answer-{{team_answers[problem.id][team.id].verdict}}">{{team_answers[problem.id][team.id].answer}}</span>
                        <br/>
                        <button type="button" class="close" aria-label="Close" (click)="set_verdict(team, problem.id, 'green')">
                            <span aria-hidden="true">v</span>
                        </button>
                        <button type="button" class="close" aria-label="Close" (click)="set_verdict(team, problem.id, 'red')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <button type="button" class="close" aria-label="Close" (click)="set_verdict(team, problem.id, 'white')">
                            <span aria-hidden="true">-</span>
                        </button>

                        <input [(ngModel)]=team_answers[problem.id][team.id].points class="points" (change)="change_points(team, problem.id)"/>
                        <br/>
                        {{team_answers[problem.id][team.id].answer_time}}
                    </td>
                </tr>
            </table>
        </div>

        <p>Результаты</p>
        <table border="2" class=table *ngIf="teams_results">
            <tr>
                <td>Команда</td>
                <td *ngFor="let game_round of game_rounds; let round_id = index">Раунд {{round_id + 1}}</td>
                <td>Итого</td>
            </tr>
            <tr *ngFor="let team of teams">
                <td><a href="/game/{{game_id}}/team/{{team.team_hash}}">{{team.name}}</a></td>
                <td *ngFor="let game_round of game_rounds">{{teams_results[team.name][game_round.id]}}</td>
                <td>{{teams_results[team.name][0]}}</td>
            </tr>
        </table>
    </div>
</div>
