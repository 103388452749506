import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-logic-field-of-miracles-problem',
  templateUrl: './logic-field-of-miracles-problem.component.html',
  styleUrls: ['./logic-field-of-miracles-problem.component.css']
})
export class LogicFieldOfMiraclesProblemComponent implements OnInit {

  @Input() problem: any = undefined;

  constructor() { }

  ngOnInit(): void {
  }

  _trackBy(index: number) {
    return index;
  }

}
