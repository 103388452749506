import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logic-music-problem',
  templateUrl: './logic-music-problem.component.html',
  styleUrls: ['./logic-music-problem.component.css']
})
export class LogicMusicProblemComponent implements OnInit {

  @Input() problem: any = undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
